import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import ListClusterCategory from './ListClusterCategory';
import ModalClusterCategory from './ModalClusterCategory';
import SearchGroup from './SearchClusterCategory';

import { setSelectedCategory, setListCategory } from '../../../../../reducers/legalCase/reducerCategoryLegalCase';
import { cleanData, setListCluster } from '../../../../../reducers/legalCase/reducerClusterCategory';
import { setSelectedCase } from '../../../../../reducers/legalCase/reducerLegalCase';
import { ID_NOT_DEFINED } from '../../../../../constant/constant';
import BreadcrumbCustom from '../../../../../app/components/BreadcrumCustom';
import GeneralService from '../../../../../services/GeneralService';

const ClusterCategoryLegalCase = () => {
    const { list: listCases, selectedCase } = useSelector(state => state.legalCaseSlice);
    const { list: listCategory, selectedCategory } = useSelector(state => state.categoryLegalCaseSlice);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const { legalCase = 0, category = 0 } = queryString.parse(search);

    const loadData = async () => {
        if (legalCase === ID_NOT_DEFINED) {
            history.push("/case-parameterization/category");
        }
        dispatch(setSelectedCase({ value: listCases.find(i => i.id === parseInt(legalCase)) }));

        if (listCategory.length === 0) {
            const service = new GeneralService("category-legal-case");
            const { categories = [] } = await service.getList(1000, { legal_case_id: legalCase });
            dispatch(setListCategory({ value: categories }));
            dispatch(setSelectedCategory({ value: categories.find(i => i.id === parseInt(category)) }));
        }

        const service = new GeneralService("cluster-category-legal-case");
        const res = await service.getList(1000, { category_legal_case_id: category });
        const { clusters = [] } = res;
        dispatch(setListCluster({ value: clusters }));
    }

    useEffect(() => {
        loadData();
    }, [listCases, legalCase, category]);

    return (
        <>
            <div className="mx-4">
                <BreadcrumbCustom
                    title="Grupos categoría"
                    routes={[
                        { name: "Tipos de caso", link: "/case-parameterization/list" },
                        { name: selectedCase.name ? selectedCase.name : "Seleccione tipo de caso", link: "/case-parameterization/list" },
                        { name: selectedCategory.name ? selectedCategory.name : "Seleccione catergoría", link: "/case-parameterization/category" },
                    ]}
                    activeRoute="Lista grupos"
                />

                <div className="row">
                    <div className="col-8">
                        <SearchGroup />
                    </div>
                    <div className="col-4">
                        <button title="Crear nueva categoría" className="btn btn-success mt-1" type="button" onClick={() => { dispatch(cleanData()); setShow(true) }}>
                            <i className="fa-solid fa-plus text-white me-2" style={{ fontSize: '16px' }} />
                            Crear grupo
                        </button>
                    </div>
                </div>
                <div className="table-data" >
                    <ListClusterCategory setShow={setShow} />
                </div>
            </div>
            <ModalClusterCategory show={show} setShow={setShow} />
        </>
    );
}

export default ClusterCategoryLegalCase;