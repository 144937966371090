import { useState } from "react";
import { useDispatch } from "react-redux";

import BreadcrumbCustom from "../../../app/components/BreadcrumCustom";
import { SearchBar } from "../../../app/components/SearchBar";
import { ModalTypeProcess } from "./components/ModalTypeProcess";

import { ListTypeProcess } from "./components/ListTypeProcess";
import { cleanData, reducerFormSearch } from "../../../reducers/expedient/reducerTypeProcess";

export const TypeProcessView = () => {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const onSearch = (e) => {
        dispatch(reducerFormSearch(e.target.value));
    }

    return (
        <>
            <div className="container-fluid max-height-overflow-y">
                <div className="row">
                    <div className="col">
                        <BreadcrumbCustom
                            title="Tipos de proceso"
                            activeRoute="Listado"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-5 mt-1">
                        <SearchBar onChange={onSearch} />
                    </div>
                    <div className="col-7">
                        <button title="Crear nuevo tipo de proceso"
                            className="btn btn-success mt-1" type="button"
                            onClick={() => { dispatch(cleanData()); setShow(true); }}
                        >
                            <i className="fa-solid fa-gears text-white me-2" />
                            Añadir
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="table-data">
                            <ListTypeProcess setShow={setShow} show={show} />
                        </div>
                    </div>
                </div>

            </div >
            <ModalTypeProcess show={show} setShow={setShow} />
        </>
    )
}
