
export const highlightErrors = (text = "", errors = []) => {
    let newText = text;

    errors.forEach(error => {
        newText = newText.replaceAll(`@${error.code}`, `<span style="color: #fff; background-color: #ba0000;">@${error.code}</span>`);
    });

    return newText;
}
