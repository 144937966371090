import React, { useState } from "react";
import { Modal } from "./index";
import GeneralService from "../../../services/GeneralService";
import { useDispatch, useSelector } from "react-redux";
import { MessageError, toast } from "../../../utils";
import { ALERT_SUCCESS } from "../../../constant/constant";
import {
  setEvents,
  setEvent,
} from "../../../reducers/calendar/reducerCalendar";
import Select from "react-select";
const Modal2 = ({getEvents}) => {
  const [modal2, setModal2] = useState(false);
  const { labels, user, event, filters } = useSelector(
    (state) => state.calendarslice
  );
  const [selectedLabel, setSelectedLabel] = useState(null);

  
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const options = user.map((item, index) => ({
    value: item.user_id,
    label: `${index} - ${item.name}`, // solo el texto para el label
    avatar: item.url_avatar, // URL del avatar
    email: item.user.email, // Email
  }));
  const formatOptionLabel = ({ avatar, label, email }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={avatar}
        alt={label}
        style={{ width: 30, height: 30, borderRadius: "50%", marginRight: 10 }}
      />
      <div>
        <div>{label}</div>
        <div style={{ fontSize: "12px", color: "#888" }}>{email}</div>
      </div>
    </div>
  );

  const handleLabelClick = (e, labelid) => {
    e.preventDefault();
    setSelectedLabel(labelid);
    //console.log(labelid);
    dispatch(setEvent({ key: "labels_id", value: [labelid] }));
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "start_date" || name === "end_date") {
      // Convertir el valor del input datetime-local a UTC ISO 8601
      const localDate = new Date(value);
      const utcDate = new Date(
        localDate.getTime() - localDate.getTimezoneOffset() * 60000
      );
      const isoString = utcDate.toISOString();

     // console.log("soy name fecha ", name, "soy value fecha ", isoString);
      dispatch(setEvent({ key: name, value: isoString }));
    } else {
      //console.log("soy name ", name, "soy value ", value);
      dispatch(setEvent({ key: name, value }));
    }
  };
  const handleSelectChange = (selectedOption, actionMeta) => {
    if (actionMeta.name === "responsible") {
      setSelectedOptions(selectedOption);
      console.log("soy responsible ", selectedOption.value);
     // console.log("soy event ", event);
      dispatch(
        setEvent({ key: "responsible_id", value: selectedOption.value })
      );
    } else if (actionMeta.name === "reporting") {
      setSelectedOptions2(selectedOption);
      console.log("soy reporting ", selectedOption.value);
      //console.log("soy event ", event);
      dispatch(setEvent({ key: "reporting_id", value: selectedOption.value }));
    }
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const service = new GeneralService("events");
    const res = await service.post(event);
    console.log(event);
    debugger;
    if (res.is_ok) {
      toast(dispatch, res.message, ALERT_SUCCESS, 5000);
      getEvents();
      setModal2(false);
      return;
    }
    MessageError(res.message);
    setModal2(false);
  };

  return (
    <div className="divcalendar-2-header">
      {selectedDate}
      <a className="a2" onClick={() => setModal2(!modal2)}>
        <i className="fa-solid fa-circle-plus fs-5 mx-1 icon2" />
      </a>
      <Modal
        State={modal2}
        setModal2={setModal2}
        titulo={"Crear un nuevo evento"}
        showHeader={true}
        showOverlay={true}
      >
        <form className="formu" onSubmit={handleSubmit}>
          <div className="containermodal2">
            <div className="labelsmodal2">
              {labels.map((item, index) => (
                <button
                  id={item.id}
                  key={index}
                  className={`btndrop ${
                    selectedLabel === item.name ? "selected" : ""
                  }`}
                  style={{
                    color: item.label_color.color_font,
                    backgroundColor: item.label_color.color,
                    border:
                      selectedLabel === item.name ? "1px solid black" : "none",
                  }}
                  onClick={(e) => handleLabelClick(e, item.id)}
                >
                  {item.name}
                </button>
              ))}
              <div className="divselecthour">
                <select
                  name="notification_time"
                  className="btn btn-secondary dropdown-toggle"
                  onChange={handleChange}
                  required
                >
                  <option selected className="dropdown-item" value="" disabled>
                    intervalo
                  </option>
                  <option className="dropdown-item" value="15M">
                    15M
                  </option>
                  <option className="dropdown-item" value="30M">
                    30M
                  </option>
                  <option className="dropdown-item" value="1H">
                    1H
                  </option>
                  <option className="dropdown-item" value="5H">
                    5H
                  </option>
                  <option className="dropdown-item" value="1D">
                    1D
                  </option>
                  <option className="dropdown-item" value="1W">
                    1W
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="divmodal2body">
            <div className="divinput">
              <i className="fa-solid fa-file-pen icomodal" />
              <input
                className="input-n2"
                type="text"
                placeholder="Titulo del evento"
                name="title"
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </div>
            <div className="divinput">
              <i className="fa-solid fa-user-plus" />
              <Select
                value={selectedOptions}
                onChange={handleSelectChange}
                options={options}
                formatOptionLabel={formatOptionLabel}
                name="responsible"
                placeholder="responsable del evento"
              />
              <Select
                value={selectedOptions2}
                onChange={handleSelectChange}
                options={options}
                formatOptionLabel={formatOptionLabel}
                name="reporting"
                placeholder="informador del evento"
              />
            </div>
            <div className="divinput">
              <i className="fa-solid fa-clock"></i>
              <input
                className="input-n2"
                type="datetime-local"
                name="start_date"
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </div>
            <div className="divinput">
              <i className="fa-solid fa-clock"></i>
              <input
                className="input-n2"
                type="datetime-local"
                name="end_date"
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </div>
            <div className="divinput">
              <i className="fa-solid fa-align-justify"></i>
              <textarea
                className="input-n2"
                placeholder="Agrega una descripción..."
                name="description"
                onChange={handleChange}
                required
                autoComplete="off"
                style={{
                  width: "713px",
                  height: "160px",
                  padding: "10px",
                }}
              />
            </div>
          </div>
          <div className="divbotones">
            <button type="submit" className="botonmodal">
              Guardar
            </button>
            <button
              type="button"
              className="botonmodal2"
              onClick={() => setModal2(false)}
            >
              Cancelar
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Modal2;
