import Swal from 'sweetalert2';

export const InvalidFieldsMessage = async( invalidFields, cleanFields ) => {
    await Swal.fire({
        title: 'Campos Inválidos',
        icon: 'warning',
        html: `Los siguientes campos son inválidos o requeridos: <br/><br/> "${invalidFields.join('",<br/> "')}".`
    }).then(result => {
        cleanFields();
    })
}