import React from "react";
import styled from "styled-components";

const Modal = ({
  children,
  State,
  setModal1,
  titulo = "Alerta",
  showHeader,
  showOverlay,
  modalType = "default", // Nueva prop para identificar el tipo de modal
}) => {
  return (
    <>
      {State && (
        <Overlay showOverlay={showOverlay}>
          <ContenedorModal modalType={modalType}>
            {showHeader && (
              <EncabezadoModal modalType={modalType}>
                <h3>{titulo}</h3>
              </EncabezadoModal>
            )}
            {children}
          </ContenedorModal>
        </Overlay>
      )}
    </>
  );
};

export default Modal;

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: ${(props) =>
    props.showOverlay ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0)"};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;

const ContenedorModal = styled.div`
  width: ${(props) =>
    props.modalType === "modal2"
      ? "569px"
      : props.modalType === "modal3"
      ? "569px"
      : "745px"};

  height: ${(props) =>
    props.modalType === "modal2"
      ? "368px"
      : props.modalType === "modal3"
      ? "430px"
      : "647px"};
  min-height: 100px;
  background: rgba(255, 255, 255, 1);
  position: relative;
  padding: 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 29px 0px;

  @media (max-width: 800px) {
    width: ${(props) =>
      props.modalType === "modal2"
        ? "clamp(20rem, 5vw + 60rem, 40rem)"
        : "clamp(11.25rem, 0.4808rem + 61.5385vw, 31.25rem)"};
  }
`;

const EncabezadoModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 20px;
  h3 {
    font-weight: 500px;
    font-size: 24px;
  }

  @media (max-width: 720px) {
    h3 {
      font-weight: 400px;
      font-size: 18px;
    }
  }
`;
