import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import GeneralService from '../../../../services/GeneralService';
import { setRefreshActors } from '../../../../reducers/documents/reducerGenerateDocument';
import { MessageError, toast } from '../../../../utils';
import IconButton from '../../../../app/components/IconButton';

const ModalLabelActor = ({ show, setShow, actorId }) => {
    const { list } = useSelector(state => state.labelGlobalSlice);
    const { listActorsSelected } = useSelector(state => state.documentsAutoSlice);
    const dispatch = useDispatch();

    const actor = listActorsSelected.find(a => a.id === actorId);

    const AddLabel = async (labelId) => {
        const service = new GeneralService("actors-documents/label/add");

        const res = await service.post({ actor_id: actorId, label_id: labelId });
        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(setRefreshActors());
            return;
        }
        MessageError(res.message, service.status);
    }

    const removeLabel = async (labelId) => {
        const service = new GeneralService("actors-documents/label/remove");
        const res = await service.post({ actor_id: actorId, label_id: labelId });
        if (res.is_ok) {
            toast(dispatch, "Proceso exitoso");
            dispatch(setRefreshActors());
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header className='bg-green pe-4' closeButton>
                <div className='container'>
                    <div className='row'>
                        <div className='col-1'>
                            <i className="fa-solid fa-circle-plus mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                        </div>
                        <div className='col'>
                            <h5 className="mt-2">
                                Etiquetas
                            </h5>
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col ms-3">
                        {
                            list.map(label => <div className="row">
                                <div className="col-10 font-lato">
                                    <i className="fa-solid fa-tags text-purple me-1"></i> {label.name}
                                </div>
                                <div className="col-2">
                                    {
                                        actor?.labels?.some(itemLabel => itemLabel.id === label.id)
                                            ?
                                            <IconButton
                                                icon="fa-solid fa-circle-xmark text-red"
                                                title="Eliminar esta etiqueta"
                                                onClick={() => removeLabel(label.id)}
                                            />
                                            :
                                            <IconButton
                                                icon="fa-solid fa-circle-plus text-green"
                                                title="Añadir esta etiqueta"
                                                onClick={() => AddLabel(label.id)}
                                            />
                                    }
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className={`btn btn-success mx-auto rounded-3 button-save-document'`}
                    onClick={() => setShow(false)}
                >
                    Listo
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalLabelActor;