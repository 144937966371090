import { useDispatch } from 'react-redux';

import BreadcrumbCustom from "../../../../app/components/BreadcrumCustom";
import CardProcess from "../../../../app/components/CardProcess";
import TabActors from "./TabActors";
import TabDialogs from "./TabDialogs";
import TabSending from "./TabSending";
import TabText from "./TabText";

import { useGenerateDocument } from "./useGenerateDocument";

const GenerateDocumentAuto = () => {
    const { dataDocument, setCurrentStep, currentStep } = useGenerateDocument();
    const dispatch = useDispatch();
    
    return (
        <div className="container-fluid max-height-overflow-y mx-2">
            <div className="">
                <div className="row m-0 p-0">
                    <div className="col-11 p-0">
                        <BreadcrumbCustom
                            title="Generar documento"
                            routes={[
                                { name: "Documentos", link: "/documents-auto/list" },
                            ]}
                            activeRoute={dataDocument.name ? dataDocument.name : "Seleccione un documento"}
                        />
                    </div>
                </div>

                <div className="row">
                    <CardProcess
                        icon="fa-users"
                        iconColor="text-green"
                        title="Actores"
                        subtitle="Número de personas que participan en la ejecución del documento"
                        isActive={currentStep === 1}
                        order={1}
                        className="rounded-start"
                        icon_style="bg-blue text-white"
                        onClick={() => setCurrentStep(1)}
                    />
                    <CardProcess
                        icon="fa-paper-plane"
                        iconColor="text-green"
                        title="Parámetros de envío"
                        subtitle="Configura la forma de envío y firma de tus clientes"
                        isActive={currentStep === 2}
                        order={2}
                        icon_style="bg-orange text-white"
                        onClick={() => setCurrentStep(2)}
                    />
                    <CardProcess
                        icon="fa-question"
                        iconColor="text-green"
                        title="Diálogos"
                        subtitle="Aquí puedes responder al cuestionario siguiendo la secuencia"
                        isActive={currentStep === 3}
                        order={3}
                        icon_style="bg-green text-white"
                        onClick={async () => setCurrentStep(3)}
                    />
                    <CardProcess
                        icon="fa-envelope-open-text"
                        iconColor="text-green"
                        title="Texto"
                        subtitle="Redacción del contenido general del documento y sus respectivos códigos"
                        isActive={currentStep === 4}
                        order={4}
                        className="rounded-end"
                        icon_style="bg-blue text-white"
                        onClick={() => setCurrentStep(4)}
                    />
                </div>

                <div className="row">
                    <div className="col-12">
                        {currentStep === 1 && <TabActors setCurrentStep={setCurrentStep} />}
                        {currentStep === 2 && <TabSending setCurrentStep={setCurrentStep} />}
                        {currentStep === 3 && <TabDialogs setCurrentStep={setCurrentStep} />}
                        {currentStep === 4 && <TabText />}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default GenerateDocumentAuto;