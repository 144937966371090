import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import es from "@fullcalendar/core/locales/es";
import Bugsnag from '@bugsnag/js';
import { useDispatch, useSelector } from "react-redux";

import {
  setFilters,
} from "../../../reducers/calendar/reducerCalendar";


export default function Calendar({ reFilter, eventosDB, onDateSelect , getEvents }) {
  const refCalendar = useRef(null);
  const [selectedInfo, setSelectedInfo] = useState(null);

  const dispatch = useDispatch();
  const {  filters } = useSelector(
    (state) => state.calendarslice
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      refCalendar.current.getApi().updateSize();
    });

    if (reFilter.current) {
      resizeObserver.observe(reFilter.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const [label, setLabel] = useState(0);

  const handleDatesSet = (info) => {
    info.start= info.start.toISOString().slice(0, 16);;
    info.end = info.end.toISOString().slice(0, 16);;

    console.log("Inicio del mes visible:", info);
   
    dispatch(setFilters({ key: 'label_id', value: 0 }));
    dispatch(setFilters({ key: 'start_date', value: info.start }));
    dispatch(setFilters({ key: 'end_date', value: info.end })); 

   
  };

  useEffect(() => {
    console.log("Estado actualizado de filters:", filters);
  }, [filters]);

  const handleDateClick = async (arg) => {
    const fecha = arg.date.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    try {
      const infoDelDia = await onDateSelect(fecha);
      setSelectedInfo(infoDelDia);
    } catch (error) {
      console.error("Error al obtener información del día:", error);
      Bugsnag.notify(new Error('Error al obtener información del día'), {
        metaData: {
          fecha: fecha,
          errorOriginal: error.toString()
        }
      });
      setSelectedInfo(null);
    }
  };

  return (
    <div>
      <FullCalendar
        ref={refCalendar}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        locale={es}
        weekends={true}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "",
        }}
        datesSet={handleDatesSet}
        events={eventosDB || []}
        dateClick={handleDateClick}
        dayCellClassNames="custom-day-cell"
      />
      {selectedInfo && (
        <div className="info-del-dia">
          <h3>Información del día seleccionado:</h3>
          <pre>{JSON.stringify(selectedInfo, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}