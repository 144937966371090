import React, { useEffect, useRef, useState } from "react";
import "./styles/calendarstyle.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setListLabels,
  setEvents,
  setEventsday,
  setListUser,
  eventUpdate,
  setFilters,
} from "../../reducers/calendar/reducerCalendar";
import GeneralService from "../../services/GeneralService";

import {
  CalendarComponent,
  Dropdowncustome,
  Modal2,
  Modal3,
  Modal1,
} from "./components/index";

const Calendar = () => {
  const { labels, events, eventsday, filters } =
    useSelector((state) => state.calendarslice);
  const dispatch = useDispatch();
  const reFilter = useRef(null);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  useEffect(() => {
    getEvents();
    getLabels();
    getEventsday();
    getUser();
  }, [filters]);

  useEffect(() => {
    // This new effect will run when selectedDate changes
    getEventsday();
  }, [selectedDate]);

  //traer Get de eventos
  const getEvents = async () => {
    const service = new GeneralService("events");
    console.log("soy filters de calendarpage ", filters);
    const event = await service.getList(1000, filters);
    dispatch(setEvents({ value: event ?? [] }));
  };

  const isSameDay = (dayOne, dayTo) => {
    return (
      dayOne.getUTCFullYear() === dayTo.getUTCFullYear() &&
      dayOne.getUTCMonth() === dayTo.getUTCMonth() &&
      dayOne.getUTCDate() === dayTo.getUTCDate()
    );
  };

  // Geteventobyid
  const getEventsday = async () => {
    const fechaObj = new Date(selectedDate); // Convertir a Date si es necesario
    const fecha = fechaObj.toISOString().split("T")[0];

    const eventDays = events.filter((event) =>
      isSameDay(new Date(event.start_date), fechaObj)
    );
    // const service = new GeneralService("events");
    // const eventsday = await service.getList(1000, { day: selectedDate });
    //console.log("soy eventsday ", eventDays);
    dispatch(setEventsday({ value: eventDays }));
  };
//get de labels
  const getLabels = async () => {
    const service = new GeneralService("labels");
    const object = await service.getList(1000);
    const labels = object.labels;
    dispatch(setListLabels({ value: labels ?? [] }));
  };

  //transoformar datos recibidos de eventos
  const transformEvents = (events) => {
    return events.map((event) => ({
      title: event.title,
      start: event.start_date,
      end: event.end_date || event.start_date,
      color: event.LabelShort ? event.LabelShort[0].color_font : null,
    }));
  };
  const transformedEvents = transformEvents(events);

  //traer usuarios
  const getUser = async () => {
    const service = new GeneralService("user");
    const object = await service.getList(1000);
    const user = object.profile;
    //console.log("soy user ", user);
    dispatch(setListUser({ value: user }));
  };

  // filtros de labels
  const eventLabel = (e, itemid) => {
    e.preventDefault();
    //console.log("soy id de los filtros ", itemid);
    //console.log(filters);
    dispatch(setFilters({ key: "label_id", value: itemid }));
  };

  //contenido de dropdown de filtros
  const dropdownContent = (
    <div className="dropdown-locale">
      {labels.map((item, index) => (
        <button
          id={item.id}
          key={index}
          className="btndrop"
          style={{
            color: item.label_color.color_font,
            backgroundColor: item.label_color.color,
          }}
          onClick={(e) => eventLabel(e, item.id)}
        >
          {item.name}
        </button>
      ))}
    </div>
  );


  //Delete de labels
  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que quieres eliminar esta etiqueta?"
    );

    if (confirmed) {
      //console.log("Elemento con ID: ", id, " eliminado");
      const service = new GeneralService(`labels`);
      const object = await service.delete(id);
      getLabels();
    }
  };


//funcion seleccionar dia
  const handleDateSelect = (date) => {
    setSelectedDate(date);
    getEventsday();
  };

  const formatTime = (timeString) => {
    if (!timeString) return "";

    const [hours, minutes] = timeString.split(":").map(Number);
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // convierte a formato 12 horas
    const formattedTime = `${formattedHours}:${
      minutes < 10 ? "0" + minutes : minutes
    } ${ampm}`;

    return formattedTime;
  };
  


  return (
    <div className="container-fluid max-height-overflow-y">
      <div className="main-div">
        <div className="div-categorias" ref={reFilter}>
          <div className="divcat2">
            {labels.map((item, index) => (
              <div
                id={item.id}
                key={index}
                className="btncal"
                style={{
                  color: item.label_color.color_font,
                  backgroundColor: item.label_color.color,
                }}
              >
                <div className="div-labels">
                  {item.name}
                  <i
                    className="fa-solid fa-x"
                    style={{
                      fontSize: "12px",
                      cursor: "pointer",
                      paddingLeft: "5px",
                    }}
                    onClick={() => handleDelete(item.id)} // Evento de clic
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="div-a">
            <div>
              <Dropdowncustome
                label={
                  <a className="a">
                    <i className="fa-solid fa-filter icon"></i>
                    <label className="textcolor">aplicar filtro</label>
                    <i className="fa-solid fa-chevron-down"></i>
                  </a>
                }
                content={dropdownContent}
                position="bottom-right"
                width={200}
              />
            </div>
                <Modal1/>
          </div>
        </div>
        <div className="container-calendar">
          <div className="divcalendar-1">
            <CalendarComponent
              reFilter={reFilter}
              eventosDB={transformedEvents}
              onDateSelect={handleDateSelect}
              getEvents={getEvents}
            >
              <div className="divcat2">
                {labels.map((item, index) => (
                  <div
                    id={item.id}
                    key={index}
                    className="btncal"
                    style={{
                      color: item.label_color.color_font,
                      backgroundColor: item.label_color.color,
                    }}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </CalendarComponent>
          </div>
          <div className="divcalendar-2">
          <Modal2
            getEvents={getEvents}
          />
            {eventsday.map((item, index) => (
              <div
                className="divcalendar-2-body"
                style={{
                  borderBottom: `8px solid ${
                    item.LabelShort
                      ? item.LabelShort[0].color_font
                      : "rgba(51, 122, 255, 1)"
                  } `,
                }}
                key={index}
              >
              
                <div className="divcalendar-2-bodyinterno">
                  <div className="bodyinterno-datetime">
                    <div>
                      {item.start_date ? item.start_date.split("T")[1] : ""}
                    </div>
                    <div>{item.notification_time}</div>
                  </div>
                  <label className="bodyinterno-title">{item.title}</label>
                </div>
                <div className="tooltiptext">
                  <div
                    className="headertooltip"
                    style={{
                      background: item.LabelShort
                        ? item.LabelShort[0].color_font
                        : "rgba(51, 122, 255, 1)",
                    }}
                  >
                    {item.title}
                  </div>
                  <div className="bodytooltip">
                    <div className="bodytooltip-text">
                      <i
                        className="fa-solid fa-clock"
                        style={{ paddingRight: "5px" }}
                      />
                      {item.start_date ? item.start_date.split("T")[0] : ""} a
                      las
                      {formatTime(
                        item.start_date ? item.start_date.split("T")[1] : ""
                      )}
                      {item.end_date &&
                      item.end_date.split("T")[0] !==
                        item.start_date.split("T")[0] ? (
                        <>
                          hasta el {item.end_date.split("T")[0]} a las
                          {formatTime(item.end_date.split("T")[1])}
                        </>
                      ) : (
                        <>
                          a las{" "}
                          {formatTime(
                            item.end_date ? item.end_date.split("T")[1] : ""
                          )}
                        </>
                      )}
                      <div>
     
                        <i
                          className="fa-solid fa-user"
                          style={{ paddingRight: "5px" }}
                        />
                        Responsable: {item.responsible}
                      </div>
                      <Modal3
                          setUpdateEvent={()=>{
                            
                            dispatch(eventUpdate({value:item}))
                          }}
                          getEvents={getEvents}
                        /> 
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
