import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import BreadcrumbCustom from '../../../../app/components/BreadcrumCustom';
import { SearchGroup } from './searchGroup';
import ListGroup from './listGroup';
import ModalGroups from './ModalGroups';

import { cleanData, setGroup } from '../../../../reducers/typePeople/reducerGroup';
import { setSelectedTypepeople } from '../../../../reducers/typePeople/reducerTypepeople';
import { ID_NOT_DEFINED } from '../../../../constant/constant';
import GeneralService from '../../../../services/GeneralService';

const TypepeopleGroup = () => {
    const { list, selectedTypepeople } = useSelector(state => state.typepeopleSlice);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const { search } = useLocation();
    const { people = 0 } = queryString.parse(search);

    const loadData = async (id) => {
        const service = new GeneralService("clusters");

        if (selectedTypepeople.id === ID_NOT_DEFINED) {
            dispatch(setSelectedTypepeople({ value: list.find(i => i.id === parseInt(people)) }));
            const { clusters = [] } = await service.getList(1000, { type_peoples_id: id });
            dispatch(setGroup({ value: clusters }));
            return;
        }
        const { clusters = [] } = await service.getList(1000, { type_peoples_id: selectedTypepeople.id });
        dispatch(setGroup({ value: clusters }));
    }

    useEffect(() => {
        loadData(people);
    }, [people, list]);

    return (
        <>
            <div className='mx-4'>
                <BreadcrumbCustom
                    title="Grupos"
                    routes={[
                        { name: "Tipos de persona", link: "/typepeople/list" },
                        { name: selectedTypepeople.name ? selectedTypepeople.name : "Seleccione tipo de persona", link: "/typepeople/list" },
                    ]}
                    activeRoute="Grupos"
                />

                <div className="container m-0 p-0">
                    <div className="row">
                        <div className="col-8">
                            <SearchGroup />
                        </div>
                        <div className="col-4">
                            <button title="Crear nuevo documento" className="btn btn-success mt-1" type="button" onClick={() => { dispatch(cleanData()); setShow(true) }}>
                                <i className="fa-solid fa-plus text-white me-2" style={{ fontSize: '16px' }} />
                                Crear grupo
                            </button>
                        </div>
                    </div>
                </div>

                <div className='table-data'>
                    <ListGroup setShow={setShow} />
                </div>
            </div>
            <ModalGroups show={show} setShow={setShow} />
        </>
    )
}

export default TypepeopleGroup;