import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SearchBar } from "../../app/components/SearchBar";
import IconButton from "../../app/components/IconButton";
import ModalGlobalCodes from "./components/ModalGlobalCodes";

import confirmDelete from "../../utils/confirmDelete";
import { cleanData, deleteFromCodes, reducerFormSearch, setList, setSelected } from "../../reducers/configuration/reducerGlobalCodes";
import { isURL } from "../Usuarios/utils/functions";
import { MessageError, MessageSuccess } from "../../utils/message";
import GeneralService from "../../services/GeneralService";

const TabCodesGlobal = () => {
    const { listFilter } = useSelector(state => state.globalCodesSlice);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const service = new GeneralService('global-codes');

    const onSearch = (e) => {
        dispatch(reducerFormSearch(e.target.value));
    }

    const loadCodes = async () => {
        const res = await service.getList();
        const { global_codes = [] } = res;
        dispatch(setList({ value: global_codes }));
    }

    const deleteCode = async (id) => {
        const res = await service.delete(id);
        if (res.is_ok) {
            MessageSuccess("El documento ha sido eliminado exitosamente!");
            dispatch(deleteFromCodes({ value: id }));
            return;
        }
        MessageError(res.message, service.status);
    }

    useEffect(() => {
        loadCodes();
    }, []);

    return (
        <div className="animate__animated animate__fadeIn">

            <div className="row">
                <div className="col-4">
                    <h5 className="font-lato">
                        <i className="fa-solid fa-earth me-2"></i>
                        Códigos globales
                    </h5>
                </div>

                <div className="col-5">
                    <div className='row'>
                        <div className="col">
                            <SearchBar onChange={onSearch} />
                        </div>
                    </div>
                </div>

                <div className="col- col-lg-3">
                    <button title="Crear un nuevo código global" className="btn btn-success d-block me-4 mt-1 ms-auto" type="button"
                        onClick={() => {
                            dispatch(cleanData());
                            setShow(true);
                        }}>
                        <i className="fa-solid fa-plus text-white me-2" style={{ fontSize: '16px' }}></i>
                        Crear
                    </button>
                </div>
            </div>

            <div className='row mt-4'>
                <div className="col-12">

                    {
                        listFilter.length === 0 ?
                            <div className="alert alert-warning text-center mt-2" role="alert">
                                No hay códigos globales que mostrar
                            </div>
                            :
                            <div className="d-block rounded-3 clip-hide">
                                <table className="table table-responsive table-header-custom animate__animated animate__fadeIn">
                                    <thead>
                                        <tr className="font-lato">
                                            <th></th>
                                            <th>#</th>
                                            <th>Nombre</th>
                                            <th>Código</th>
                                            <th>Valor</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listFilter.map((item, i) => (
                                                <tr key={item.id} className="font-rubik">
                                                    <td></td>
                                                    <td>{i + 1}.</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.code}</td>
                                                    <td>{isURL(item.value) ? <a href={item.value} target="_blank">Ver contenido</a> : item.value}</td>
                                                    <td>
                                                        <IconButton
                                                            icon="fa-solid fa-pen-to-square text-green"
                                                            title="Editar código"
                                                            onClick={() => {
                                                                let field = { ...item };
                                                                if (item.type_fields_id === 5) field.value = field.value.substring(0, 19);
                                                                dispatch(setSelected({ value: field }));
                                                                setShow(true);
                                                            }}
                                                        />
                                                        <IconButton
                                                            icon="fa-solid fa-trash-can text-red"
                                                            title="Eliminar código"
                                                            onClick={async () => await confirmDelete(() => deleteCode(item.id))}
                                                        />
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                    }

                </div>
            </div>

            <ModalGlobalCodes show={show} setShow={setShow} />

        </div>
    );
}

export default TabCodesGlobal;