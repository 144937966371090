import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MessageError, MessageSuccess } from '../../../../../utils/message';
import IconButton from '../../../../../app/components/IconButton';

import { deleteParameterization, orderParameterization } from '../../../../../reducers/forms/reducerParameterizationActorForm';
import confirmDelete from '../../../../../utils/confirmDelete';
import GeneralService from '../../../../../services/GeneralService';
import { useAnimateBackground } from '../../../../../app/hooks/useAnimateBackground';
import { showAnimateOrderItem } from '../../../../../utils/showAnimateOrderItem';

const ListParameterizationActorForm = () => {
    const { listFilter } = useSelector(state => state.parameterizationActorFormSlice);
    const dispatch = useDispatch();
    const history = useHistory();
    const [showEffect, setShowEffect] = useAnimateBackground();

    const deleteParam = async (id) => {
        const service = new GeneralService("parameterization-actor");
        const res = await service.delete(id);
        if (res.is_ok) {
            MessageSuccess("El registro ha sido eliminado exitosamente!");
            dispatch(deleteParameterization({ value: id }));
            return;
        }
        MessageError("No fue posible eliminar la parametrización");
    }

    const handleEdit = (item) => {
        history.push({
            pathname: `/form-parameterization/actors/fields/update/${item.id}`,
            search: `?form=${item.form_id}&actor=${item.actor_form_id}`,
        });
    }

    const handleMove = async (id, order) => {
        const service = new GeneralService("parameterization-actor/order");
        const res = await service.post({ id, order });
        if (res.is_ok) {
            dispatch(orderParameterization({ value: { id, order } }));
            setShowEffect({ show: true, id });
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <>
            <div className="order">
                {
                    listFilter.length === 0 ?
                        <div className="alert alert-warning text-center mt-2" role="alert">
                            No hay campos que mostrar
                        </div>
                        :
                        <div className="d-block rounded-3 clip-hide">
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>#</th>
                                        <th>Campo</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFilter.map((item, i) => (
                                            <tr key={item.id} className={`change-background-animation ${showAnimateOrderItem(item, showEffect)}`}>
                                                <td></td>
                                                <td>{i + 1}.</td>
                                                <td>{item.name}</td>
                                                <td>
                                                    <IconButton
                                                        icon="fa-solid fa-pen-to-square text-green"
                                                        title="Editar parametrización"
                                                        onClick={() => handleEdit(item)}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-trash-can text-red"
                                                        title="Eliminar parametrización"
                                                        onClick={async () => await confirmDelete(() => deleteParam(item.id))}
                                                    />
                                                    <div className="move-parameterization">
                                                        <i className="fa-solid fa-circle-arrow-up text-green me-2" role='button' onClick={() => handleMove(item.id, (i - 1))}></i>
                                                        <i className="fa-solid fa-circle-arrow-down text-purple" role='button' onClick={() => handleMove(item.id, (i + 1))}></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </div>
        </>
    )

}

export default ListParameterizationActorForm;