import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import BreadcrumbCustom from "../../app/components/BreadcrumCustom";
import { ListImports } from "./components/ListImports";

import GeneralService from "../../services/GeneralService";
import { reducerFormSearch, setList } from "../../reducers/importer/reducerImporter";
import { CustomPagination } from "../../app/components/CustomPagination";
import { SearchBar } from "../../app/components/SearchBar";

export const ImporterView = () => {
    const [pagination, setPagination] = useState({ total_pages: 0, page: 0, page_size: 10 });
    const dispatch = useDispatch();
    const history = useHistory();

    const loadImports = async () => {
        const service = new GeneralService("data-import");
        const res = await service.getList(0, pagination);
        const { content = [], total_pages = 0 } = res;
        dispatch(setList(content));
        setPagination({ ...pagination, total_pages });
    }

    const handleCreate = () => {
        history.push("/importers/create")
    }

    useEffect(() => {
        loadImports();
    }, [pagination.page, pagination.page_size]);

    return (
        <div className="container-fluid max-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title="Importadores"
                        activeRoute="Listar importaciones"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-6 mt-1">
                    <SearchBar
                        placeholder="Buscar por nombre"
                        onChange={(e) => dispatch(reducerFormSearch(e.target.value))}
                    />
                </div>
                <div className="col-6">
                    <button title="Crear nuevo documento" className="btn btn-success rounded-pill mt-1" type="button" onClick={handleCreate}>
                        <i className="fa-solid fa-gear text-white me-2" />
                        Añadir
                    </button>
                </div>
            </div>
            <div className="table-data" >
                <ListImports />
            </div>
            <CustomPagination
                pagination={pagination}
                setPagination={setPagination}
            />
        </div >
    );
}