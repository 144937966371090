import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as XLSX from 'xlsx';

import BreadcrumbCustom from "../../app/components/BreadcrumCustom";

import GeneralService from "../../services/GeneralService";
import { encodeFileBase64, MessageError, MessageSuccess } from "../../utils";
import { ConfigMappings } from "./components/ConfigMappings";
import { COLLECTION_DOCUMENTS, COLLECTION_PEOPLE } from "../../constant/constant";
import { setListParametrizeDocuments } from "../../reducers/documents/reducerGenerateDocument";
import { setListMapping, setListMappingActors } from "../../reducers/importer/reducerImporter";
import { hideLoading, showLoading } from "../../reducers/main/loadingReducer";

export const CreateImportView = () => {
    const { listMapping, listMappingActors } = useSelector(state => state.reducerImporterSlice);
    const { list } = useSelector(state => state.typepeopleSlice);
    const { list: listTemplates } = useSelector(state => state.documentsAutoSlice);
    const [fileData, setFileData] = useState([]);
    const [document, setDocument] = useState("");
    const [fileName, setFileName] = useState("");
    const [collection, setCollection] = useState("");
    const [typePeople, setTypePeople] = useState("");
    const [typeDocument, setTypeDocument] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();

    const encodeDocument = async (file) => {
        const base64 = await encodeFileBase64(file);
        if (base64) {
            setDocument(base64);
            return;
        }
        MessageError("No es un documento válido");
    }

    const handleFileUpload = (e) => {
        encodeDocument(e.target.files[0]);

        const file = e.target.files[0];
        setFileName(file.name);
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0];
            const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

            const firstTwoRows = worksheet.slice(0, 2);
            setFileData(firstTwoRows);
        };

        reader.readAsArrayBuffer(file);
    };

    const loadTemplates = async () => {
        const service = new GeneralService("documents-auto");
        const res = await service.getList(1000);
        const { documents = [] } = res;
        dispatch(setListParametrizeDocuments({ value: documents }));
    }

    const handleCreateImport = async () => {
        const data = {
            collection,
            type_peoples_id: typePeople ? parseInt(typePeople) : undefined,
            documents_auto_id: typeDocument ? parseInt(typeDocument) : undefined,
            mappings: [...listMapping, ...listMappingActors],
            file: `${document}--EXTENSION--xlsx`,
            file_name: fileName,
        }

        const service = new GeneralService("data-import");
        dispatch(showLoading());
        const res = await service.post(data);
        dispatch(hideLoading());
        if (res.is_ok) {
            MessageSuccess(res.message);
            history.push("/importers");
            return;
        }
        MessageError(res.message);
    }

    useEffect(() => {
        loadTemplates();

        return () => {
            dispatch(setListMapping([]));
            dispatch(setListMappingActors([]));
        }
    }, []);

    return (
        <div className="container-fluid max-height-overflow-y min-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title="Importadores"
                        activeRoute="Crear importación"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <label>Cargue documento<b><i className="text-red">*</i></b></label>
                    <input
                        type="file"
                        className="form-control green-input mb-1"
                        onChange={handleFileUpload}
                        required={true}
                    />

                    <label>Colección<b><i className="text-red">*</i></b></label>
                    <select
                        className="form-select green-input mb-1"
                        name="collection"
                        value={collection}
                        onChange={(e) => setCollection(e.target.value)}
                        required
                    >
                        <option value="">Seleccione...</option>
                        <option value={COLLECTION_PEOPLE}>Personas</option>
                        <option value={COLLECTION_DOCUMENTS}>Documentos</option>
                    </select>

                    {
                        collection === COLLECTION_PEOPLE
                            ?
                            <>
                                <label>Tipo de persona<b><i className="text-red">*</i></b></label>
                                <select
                                    className="form-select green-input mb-1"
                                    name="type_people_id"
                                    value={typePeople}
                                    onChange={(e) => setTypePeople(e.target.value)}
                                >
                                    <option value="">Seleccione...</option>
                                    {
                                        list.map(typeP => (<option value={typeP.id} key={typeP.id}> {typeP.name}</option>))
                                    }
                                </select>
                            </>
                            :
                            <>
                                <label>Plantilla<b><i className="text-red">*</i></b></label>
                                <select
                                    className="form-select green-input mb-1"
                                    name="documents_auto_id"
                                    value={typeDocument}
                                    onChange={(e) => setTypeDocument(e.target.value)}
                                >
                                    <option value="">Seleccione...</option>
                                    {
                                        listTemplates.map(item => (<option value={item.id} key={item.id}>{item.name}</option>))
                                    }
                                </select>
                            </>
                    }

                </div>
                <div className="col-lg-8">
                    <ConfigMappings
                        firstRow={fileData[0]}
                        collection={collection}
                        typePeople={typePeople}
                        typeDocument={typeDocument}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <button title="Crear importación" className="btn btn-success mt-3 d-block mx-auto" type="button" onClick={handleCreateImport}>
                        <i className="fa-solid fa-file-import text-white me-2" />
                        Crear
                    </button>
                </div>
            </div>
        </div>
    );
}