import { Accordion } from "react-bootstrap";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import CaseCategoriesClusters from "./CaseCategoriesClusters";

const AccordionCategory = ({ category, encodeDocument, id }) => {
    return (
        <>
            {
                category.clusters?.length === undefined ?
                    <div className="alert alert-warning" role="alert" style={{ marginBottom: '0px', margin: '10px 0px' }}>
                        ¡ No hay grupos asociados a la categoría: "{category.name}" !
                    </div>
                    :
                    <Accordion
                        className="m-0 p-0 accordion-stepper-form rounded-4"
                        defaultActiveKey={['0']}
                        alwaysOpen={false}
                        flush
                    >
                        <AccordionItem
                            className="accordion-item-stepper-form"
                            eventKey={category.id}
                        >
                            <Accordion.Header className="accordion-header-stepper-form">
                                <div className='container-fluid p-0'>
                                    <div className='row m-0 px-3'>
                                        <div className='col px-0' style={{ paddingTop: '3px' }}>
                                            <span className='spanLetter' style={{ fontSize: '17px' }}> <strong>{category.name}</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Header>
                            <AccordionBody className="m-0 p-0">
                                <>
                                    <CaseCategoriesClusters
                                        category={category}
                                        clusters={category.clusters}
                                        encodeDocument={encodeDocument}
                                        id={id}
                                    />
                                </>
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
            }
        </>
    )
}

export default AccordionCategory;