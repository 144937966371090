import React, { useEffect, useState } from 'react';
import '../styles/calendarstyle.css'
import GeneralService from "../../../services/GeneralService";
import { useDispatch, useSelector } from "react-redux";
import {
setLabel,
setListColors
} from "../../../reducers/calendar/reducerCalendar";



const ColorPicker = ({ onColorSelect ,update }) => {
  const [selectedColor, setSelectedColor] = useState(null);
  const dispatch = useDispatch();
  const { colors  , oncelabel} = useSelector(
    (state) => state.calendarslice
  );

  const requestColor = async ()=>{
    const service = new GeneralService("label-color");
    const { LabelColor}  = await  service.getList(0)
    dispatch(setListColors({value : LabelColor}))
  }

  useEffect(()=>{
   
    requestColor()
  },[])



  // const colors = [
  //   'rgba(0, 186, 52, 1)', 'rgba(0, 133, 255, 1)', 'rgba(255, 159, 45, 1)', 'rgba(233, 44, 44, 1)', 'rgba(25, 135, 84, 1)',
  //   'rgba(79, 208, 148, 1)', 'rgba(255, 195, 125, 1)', 'rgba(255, 146, 146, 1)', 'rgba(98, 43, 255, 1)', 'rgba(240, 27, 117, 1)',
  //   'rgba(122, 255, 159, 1)', 'rgba(158, 208, 255, 1)', 'rgba(255, 195, 124, 1)', 'rgba(255, 115, 115, 1)', 'rgba(98, 255, 143, 1)',
  //   'rgba(242, 255, 92, 1)', 'rgba(250, 255, 7, 1)', 'rgba(97, 227, 255, 1)', 'rgba(106, 112, 255, 1)', 'rgba(232, 63, 184, 1)'
  // ];

  const handleColorClick = (e, color) => {
    e.preventDefault(); 
    e.stopPropagation(); 
    setSelectedColor(color.color_font);
      dispatch(setLabel({
        key: "label_color_id",
        value: color.id
      }));
  };


  const isSelectedColor = (color)=>{
    if(update){
       return oncelabel?.label_color?.color_font == color.color_font
    }
    return selectedColor == color.color_font
  }

  return (
    <div className='containerStyle'>
      <h2 className='title-style'>Elige un color</h2>
      <div className='colorGridStyle' >
        {colors.map((color, index) => (
          <button
          className='colorButtonStyle'
            key={index}
            style={{
              backgroundColor: color.color_font,
              border: isSelectedColor(color)? '2px solid black' : 'none'
            }}
            onClick={(e) => handleColorClick(e, color)}
          />
        ))}
      </div>
    </div>
  );
};

export default ColorPicker;