import { useDispatch, useSelector } from "react-redux";
import { searchNumberBusiness, searchTextBusiness } from '../../../reducers/business/reducerBusiness';

export const SearchBusiness = () => {

    const filtered = useSelector(state => state.businessSlice.filterNumDocument)
    const dispatch = useDispatch();

    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="row m-0 p-0">

                    <div className='col-6'>
                        <input type="text" className="form-control"
                            placeholder="Buscar por nombre"
                            onChange={(e) => {
                                dispatch(searchTextBusiness({
                                    value: e.target.value
                                }))
                            }}
                        />
                    </div>

                    <div className='col-6'>
                        <input type="text" className="form-control"
                            placeholder="Buscar por NIT o CC"
                            value={filtered}
                            onChange={(e) => {
                                const NumberRegex = /^[0-9\-]{0,11}$/;
                                if (NumberRegex.test(e.target.value)){
                                    dispatch(searchNumberBusiness({
                                        value: e.target.value
                                    }))
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );

}