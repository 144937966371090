import { useState } from "react";
import IconButton from "../../../app/components/IconButton";
import { hideToast, showToast } from "../../../reducers/main/toastReducer";
import { useDispatch } from "react-redux";

const SelectControl = ({ fields = "", onChange }) => {
    const [optionSelect, setOptionSelect] = useState("");
    const dispatch = useDispatch();

    const toast = (message) => {
        dispatch(showToast({
            type: "alert-warning",
            message,
        }));
        setTimeout(() => {
            dispatch(hideToast());
        }, 3000);
    }

    const handleRegister = () => {
        if (!optionSelect) {
            toast("Opción vacía no permitida");
            return;
        }
        if (fields.includes(optionSelect.toUpperCase()) && fields !== "") {
            toast("La opción ya se encuentra registrada");
            return;
        }
        if (optionSelect) {
            onChange(`${fields}${optionSelect.toUpperCase()}*`);
            setOptionSelect("");
        }
    }

    return (
        <>
            <label>¿Cuáles serán las opciones?<b><i className="text-red">*</i></b></label>
            <div className="style-form__select">
                {
                    fields.split("*")?.map((item, i) => {
                        if (item !== "") {
                            return (
                                <div className="row p-1 ps-2 mx-1 border-bottom" key={item}>
                                    <div className="col">
                                        {i + 1}. {item}
                                    </div>
                                    <div className="col-1">
                                        <IconButton
                                            icon="fa-solid fa-circle-xmark text-red"
                                            title="Eliminar opción"
                                            onClick={() => {
                                                const edited = fields.replace(item + "*", "");
                                                onChange(edited);
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        return;
                    })
                }
                <div className="row p-1 mx-1 mt-1">
                    <div className="col">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Nombre de la opción"
                            value={optionSelect}
                            onChange={(e) => setOptionSelect(e.target.value)}
                            onSubmitCapture={handleRegister}
                        />
                    </div>
                    <div className="col-1 pt-1">
                        <IconButton
                            icon="fa-solid fa-circle-plus text-green"
                            title="Agregar opción"
                            onClick={handleRegister}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectControl; 