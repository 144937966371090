import '../styles/search-input.css'
import { useDispatch } from 'react-redux';
import { searchCase } from '../../../reducers/legalCase/reducerLegalCase';

const SearchLegalCase = () => {
    const dispatch = useDispatch();

    return (

        <div className="d-flex justify-content-start align-items-center principal-div-search-content">
            <div className="second-div-search-content">
                <ul>
                    <li>
                        <button className="primary-item-search-lupa"> <i className="fas fa-search"></i></button>
                    </li>

                    <li className="second-item-search-input-text">
                        <input type="text"
                            className="align-items-center"
                            placeholder="Digite el tipo de persona a buscar"
                            onChange={(v) => dispatch(searchCase({
                                value: v.target.value
                            }))}
                        />
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default SearchLegalCase;