import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { MessageError, MessageSuccess } from '../../../utils/message';

import { ID_NOT_DEFINED } from '../../../constant/constant';
import { addNewObjectToList, cleanData, setDataTypepeople, updateNewObjectAfterEdit } from '../../../reducers/typePeople/reducerTypepeople';
import GeneralService from '../../../services/GeneralService';

const ModalTypePeople = ({ show, setShow }) => {
    const { selectedTypepeople } = useSelector(state => state.typepeopleSlice);
    const dispatch = useDispatch();

    const setData = (e) => {
        dispatch(setDataTypepeople({
            key: e.target.name,
            value: e.target.value
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const service = new GeneralService("typepeople");

        const data = {
            id: selectedTypepeople?.id,
            name: selectedTypepeople?.name,
            description: selectedTypepeople?.description
        };

        if (selectedTypepeople?.id === ID_NOT_DEFINED) {
            const res = await service.post(data);

            if (res.is_ok) {
                setShow(false);
                MessageSuccess(res.message);
                dispatch(addNewObjectToList({ value: res.type_people }));
                dispatch(cleanData());
                return;
            }
            MessageError(res.message, service.status);
            return;
        }
        const res = await service.update(data);
        if (res.is_ok) {
            MessageSuccess(res.message);
            dispatch(updateNewObjectAfterEdit({ value: data }));
            setShow(false);
            dispatch(cleanData());
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <form onSubmit={handleSubmit} autoCorrect="on">
                <Modal.Header className='bg-green pe-4' closeButton>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-1'>
                                {
                                    selectedTypepeople?.id === ID_NOT_DEFINED ?
                                        <i className="fa-solid fa-circle-plus mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                        :
                                        <i className="fa-solid fa-pen-to-square mt-2 animate__animated animate__backInRight" style={{ fontSize: 24 }}></i>
                                }
                            </div>
                            <div className='col'>
                                <h5 className="mt-2">
                                    {
                                        selectedTypepeople?.id === ID_NOT_DEFINED ?
                                            "Crear Tipo de Persona"
                                            :
                                            "Editar Tipo de Persona"
                                    }
                                </h5>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-body">

                        <div className="row m-0 p-0">

                            <div className='mb-3'>
                                <label>Nombre<b><i className="text-red">*</i></b></label>
                                <input
                                    type="text"
                                    className="form-control mt-1"
                                    name="name"
                                    value={selectedTypepeople?.name}
                                    maxLength={50}
                                    minLength={3}
                                    placeholder="Ingrese un nombre"
                                    autoComplete="off"
                                    autoCapitalize="words"
                                    onChange={setData}
                                    required
                                />
                            </div>

                            <div className='mb-3'>
                                <label>Descripción</label>
                                <textarea
                                    className="form-control custom-textarea mt-1"
                                    name="description"
                                    value={selectedTypepeople?.description}
                                    maxLength={255}
                                    placeholder="Ingrese un nombre"
                                    onChange={setData}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className={`btn btn-success mx-auto rounded-3 button-save-document'`}>
                        <i className="fa-solid fa-paper-plane me-2" style={{ fontSize: '16px' }}></i>
                        {
                            selectedTypepeople?.id === ID_NOT_DEFINED ?
                                "Agregar"
                                :
                                "Actualizar"
                        }
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ModalTypePeople;