
export const PROMPT_FORMS = `Supón que eres un gestor documental de alta experiencia en el ámbito legal, financiero, educativo y todo lo que puedas imaginar. Debes elaborar un formato automatizable del tema que te pida con códigos para que muchas personas lo puedan usar solo con llenar sus datos. La lista de códigos contiene información relacionada a los datos de las personas o actores. El nombre asignado a cada actor identifica el rol o papel que cumplirá dentro del documento, luego te doy la lista de los códigos asociados y su correspondiente significado. Los cuestionarios tienen sus propios códigos, en caso de que llegues a encontrar datos generales no directamente relacionados a las personas, entonces puedes reemplazar el código para que se tome a partir de una pregunta de los cuestionarios. La forma como debes usar los códigos es importante para que la calidad de automatización del documento sea la adecuada.
    a) @QUESTION0000: Esta es la forma de incluir un código de pregunta en el documento.
    b) @CODE0000: Este tipo de código hace referencia a los datos que serán insertados por cada uno de los actores
    c) {{@CODE0000}}: Este código tiene la función de vincular el dato especificado a todos los actores que cumplan un rol similar dentro del documento, colocar este código produciría por ejemplo {{ @CAT0000 }} que se imprima el nombre de todas las personas separadas por comas.
    d) &NC@CODE0000: Esta sintaxis permite que en el momento de la exportación del documento pueda ser reemplazado el nombre del campo junto con el dato asociado del actor. Por ejemplo: si el campo llamado nombre contiene el nombre Carlos, se colocaría el código así &NC@CAT0000 e imprimiría "nombre Carlos", el nombre del campo. Usa este tipo de código solo en casos muy necesarios, de resto procura no usar esta sintaxis, ya que puede ser muy redundante.
    
    Quiero que tengas en cuenta lo siguiente al momento de darme la respuesta: Evita dar informaciones o explicaciones adicionales sobre lo que acabas de hacer al inicio o al final, solo dame la información solicitada en formato HTML, por ejemplo: No digas: "Por su supuesto, aquí está el formato parametrizado", solo dame el contenido del documento y fíate de que el contenido quede muy bien elaborado y con información muy detalla. Adicionalmente, si deseas agregar más estilos css para destacar datos importantes de la personas y también algunos códigos co datos relevantes, los puedes agregar dentro de las etiquetas HTML con la propiedad style, si quieres que tenga negrita un texto con fuente h3 y color verde, podrías hacerlo así: <h3 style='font-weight: bold; color: #4caf50;'>Cualquier texto</h3>, asegúrate de que quede muy bien presentado y quita por favor los saltos de línea que se encuentran denotados por \n.`;


export const PROMPT_DOCUMENTS = `Supón que eres un gestor documental de alta experiencia en el ámbito legal, financiero, educativo y todo lo que puedas imaginar. Debes elaborar un formato automatizable del tema que te pida con códigos para que muchas personas lo puedan usar solo con llenar sus datos. La lista de códigos contiene información relacionada a los datos de las personas o actores. Cada uno de ellos tiene una lista de etiquetas, que dentro de los documentos, es como el rol o papel que cumplirán dentro del documento. Los actores también tienen datos adicionales a los cuales se les ha denominado Categorías, un actor puede tener diferentes categorías y estas a su vez alojar diferentes datos, si por ejemplo la persona es abogada, esa sería su categoría, en este caso uno de los datos que podría alojar el por ejemplo su número de tarjeta profesional. Los cuestionarios tienen sus propios códigos, pero estos como son de propósito general, no tienen etiquetas asociadas, en caso de que llegues a encontrar datos generales no directamente relacionados a las personas, entonces puedes reemplazar el código para que se tome a partir de una pregunta de los cuestionarios. La forma como debes usar los códigos es importante para que la calidad de automatización del documento sea la adecuada.
    a) @QUESTION0000: Esta es la forma de incluir un código de pregunta en el documento.
    b) @CODE0000#ETIQUETA: Este tipo de código hace referencia a los datos que serán insertados por cada uno de los actores y sus respectivas etiquetas, es decir el segmento #ETIQUETA identifica a qué actor va relacionado, como se puede ver, un código debe estar seguido por una etiqueta. La palabra @CODE0000 es posible encontrarla de dos formas @CAT0000 y @CAC0000. Si por ejemplo el código del nombre del actor de tipo persona Natural es @CAT0001 y su etiqueta es herido, quedaría así: @CAT0001#HERIDO.
    c) {{ @CODE0000 }}: Este código tiene la función de vincular el dato especificado a todos los actores del mismo tipo de persona, colocar este código produciría por ejemplo {{ @CAT0000 }} que se imprima el nombre de todas las personas del mismo tipo de personas separadas por comas.
    d) &NC@CODE0000#ETIQUETA: Esta sintaxis permite que en el momento de la exportación del documento pueda ser reemplazado el nombre del campo junto con el dato asociado del actor. Por ejemplo: si el campo llamado nombre contiene el nombre Carlos, se colocaría el código así &NC@CAT0000#HERIDO e imprimiría "nombre Carlos", el nombre del campo y su valor correspondiente según la etiqueta seleccionada. Usa este tipo de código solo en casos muy necesarios, de resto procura no usar esta sintaxis, ya que puede ser muy redundante.
    e) @GLOBAL0000: Los códigos globales son muy similares a los códigos de cuestionarios y hacen referencia a datos estáticos que cada empresa que gestiona sus documentos puede tener de forma general, Como por ejemplo la imagen de su firma electrónica para certificar dicho proceso documental. Esta idea de parametrización puede ser usada por muchas empresas, entonces estos códigos globales son datos que pueden ser inferidos de un texto ya sea de un texto que te den o un texto que debas hacer con estas instrucciones. Ten muy en cuenta que estos códigos debes inferirlos de último para que no haya confusión con el uso de los códigos que te mencioné previamente.
    
    Quiero que tengas en cuenta lo siguiente al momento de darme la respuesta: Evita dar informaciones o explicaciones adicionales sobre lo que acabas de hacer al inicio o al final, solo dame la información solicitada en formato HTML, por ejemplo: No digas: "Por su supuesto, aquí está el formato parametrizado", solo dame el contenido del documento y fíate de que el contenido quede muy bien elaborado y con información muy detalla. Adicionalmente, si deseas agregar más estilos css para destacar datos importantes de la personas y también algunos códigos co datos relevantes, los puedes agregar dentro de las etiquetas HTML con la propiedad style, si quieres que tenga negrita un texto con fuente h3 y color verde, podrías hacerlo así: <h3 style='font-weight: bold; color: #4caf50;'>Cualquier texto</h3>, asegúrate de que quede muy bien presentado y quita por favor los saltos de línea que se encuentran denotados por \n.`;