import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { deleteCluster, setSelectedCluster } from '../../../../../reducers/legalCase/reducerClusterCategory';
import { setData } from '../../../../../reducers/copyClusters/reducerCopyGroup';
import { MessageError, MessageSuccess } from '../../../../../utils/message';
import confirmDelete from '../../../../../utils/confirmDelete';
import ModalCopyGroup from '../../../../CopyClusters/ModalCopyGroup';
import IconButton from '../../../../../app/components/IconButton';
import GeneralService from '../../../../../services/GeneralService';

const ListClusterCategory = ({ setShow }) => {
    const { listFilter } = useSelector(state => state.clusterCategoryLegalCaseSlice);
    const [showCopy, setShowCopy] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const { legalCase = 0 } = queryString.parse(search);

    const deleteGroup = async (id) => {
        const service = new GeneralService("cluster-category-legal-case");
        const res = await service.delete(id);
        if (res.is_ok) {
            MessageSuccess(res.message);
            dispatch(deleteCluster({ value: id }));
            return;
        }
        MessageError(res.message, service.status);
    }

    const handleShowParams = (item) => {
        dispatch(setSelectedCluster({ value: item }));
        history.push({
            pathname: "/case-parameterization/category/fields",
            search: `?legalCase=${legalCase}&category=${item.category_legal_case_id}&cluster=${item.id}`,
        });
    }

    const handleCopyGroup = (item) => {
        setShowCopy(true);
        dispatch(setData({
            value: {
                cluster_id: item.id,
                cluster_is_category: true,
            }
        }));
    }

    return (
        <>
            <div className="order">
                {
                    listFilter.length === 0 ?
                        <div className="alert alert-warning text-center mt-1" role="alert">
                            No hay grupos de la categoría que mostrar
                        </div>
                        :
                        <div className="d-block rounded-3 clip-hide">
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>#</th>
                                        <th>Nombre del grupo </th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFilter.map((item, i) => (
                                            <tr key={item.id}>
                                                <td></td>
                                                <td>{i + 1}.</td>
                                                <td>{item.name}</td>
                                                <td>
                                                    <IconButton
                                                        icon="fa-solid fa-pen-to-square text-green"
                                                        title="Editar grupo"
                                                        onClick={() => {
                                                            dispatch(setSelectedCluster({ value: item }));
                                                            setShow(true);
                                                        }}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-trash-can text-red"
                                                        title="Eliminar grupo"
                                                        onClick={async () => await confirmDelete(() => deleteGroup(item.id))}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-gear text-purple"
                                                        title="Parametrizar grupo"
                                                        onClick={() => handleShowParams(item)}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-copy text-purple"
                                                        title="Copiar grupo a"
                                                        onClick={() => handleCopyGroup(item)}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                }
            </div>
            <ModalCopyGroup show={showCopy} setShow={setShowCopy} model="CASES"/>
        </>
    );
}

export default ListClusterCategory;