import { useSelector } from "react-redux";

export const ToastProgress = () => {
    const { show } = useSelector(state => state.toastProgressSlice);

    return (
        show &&
        <div className="alert alert-success toast-custom" role="alert">
            <div className="row m-0 p-0">
                <div className="col-1">
                    <div className="spinner-border text-success" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div className="col d-flex align-items-center">
                    Descarga en progreso
                </div>
            </div>
        </div>
    );
};