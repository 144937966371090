import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BreadcrumbCustom from "../../app/components/BreadcrumCustom";
import { SearchBar } from "../../app/components/SearchBar";
import ModalRole from "./components/ModalRole";

import { ListRoles } from "./components/ListRoles";
import { cleanData, searchRole, setRole, setSelectedRole } from "../../reducers/roles/reducerRoles";
import GeneralService from "../../services/GeneralService";
import { CustomPagination } from "../../app/components/CustomPagination";
import { ListPermissions } from "./components/ListPermissions";
import { setList } from "../../reducers/roles/reducerPermissions";

export const RolesView = () => {
    const { selectedRole } = useSelector(state => state.rolesSlice);
    const [show, setShow] = useState(false);
    const [pagination, setPagination] = useState({ total_pages: 0, page: 0, page_size: 10 });
    const dispatch = useDispatch();

    const handleSearch = (e) => {
        dispatch(searchRole(e.target.value));
    }

    const getRole = async () => {
        const service = new GeneralService("roles");
        const response = await service.getList(0, pagination);
        const { roles = [], total_pages = 0 } = response;
        dispatch(setRole(roles));
        setPagination({ ...pagination, total_pages });
        if (roles.length > 0) dispatch(setSelectedRole(roles[0]));
    }

    const loadPermissions = async () => {
        const service = new GeneralService("permissions");
        const response = await service.getList(0, { role_id: selectedRole.id });
        const { permissions = [] } = response;
        dispatch(setList(permissions));
    }

    useEffect(() => {
        loadPermissions();
    }, [selectedRole.id]);

    useEffect(() => {
        getRole();
    }, []);

    return (
        <div className="container-fluid max-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title="Roles"
                        activeRoute="Listar"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-5">
                    <div className="row">
                        <div className="col-9 mt-1">
                            <SearchBar onChange={handleSearch} />
                        </div>
                        <div className="col-3">
                            <button title="Crear nuevo rol" className="btn btn-success mt-1" type="button" onClick={() => { dispatch(cleanData()); setShow(true) }}>
                                <i className="fa-solid fa-plus text-white me-2" />
                                Crear
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="table-data" >
                                <ListRoles setShow={setShow} />
                            </div>
                        </div>
                    </div>

                    <CustomPagination
                        pagination={pagination}
                        setPagination={setPagination}
                    />
                </div>
                <div className="col-7">
                    <h5 className="font-lato fw-bold mt-2 mb-4">Permisos</h5>
                    <div className="table-data" >
                        <ListPermissions />
                    </div>
                </div>
            </div>

            <ModalRole show={show} setShow={setShow} />
        </div>
    )
};