import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { searchCategory } from '../../../../reducers/legalCase/reducerCategoryLegalCase';
import { setSelectedCase } from '../../../../reducers/legalCase/reducerLegalCase';

import '../../styles/search-input.css';

const SearchCategoryLegalCase = () => {
    const { list, selectedCase } = useSelector(state => state.legalCaseSlice);
    const dispatch = useDispatch();
    const history = useHistory();

    const setData = async (e) => {
        const id = parseInt(e.target.value);
        dispatch(setSelectedCase({ value: list.find(i => i.id === id) }));
        history.push(`?legalCase=${e.target.value}`);
    }

    return (

        <div className="d-flex justify-content-start align-items-center principal-div-search-content">
            <div className="second-div-search-content">
                <ul>
                    <li>
                        <button className="primary-item-search-lupa"> <i className="fas fa-search"></i></button>
                    </li>

                    <li className="second-item-search-input-text">
                        <input
                            type="text"
                            className="align-items-center"
                            placeholder="Digite la categoría a buscar"
                            onChange={(v) => dispatch(searchCategory({
                                value: v.target.value
                            }))}
                        />
                    </li>

                    <li className="third-item-search-select">
                        <select
                            className="align-items-left"
                            style={{ outline: "none" }}
                            name="type_people_id"
                            value={selectedCase.id}
                            onChange={setData}
                        >
                            <option value="">
                                Seleccione el tipo de caso
                            </option>
                            {
                                list.map(item => (<option value={item.id} key={item.id}>
                                    {item.name}
                                </option>))
                            }
                        </select>
                    </li>

                </ul>
            </div>
        </div>
    );
}

export default SearchCategoryLegalCase; 