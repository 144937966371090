import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import CategoryGroupListParameterize from './listParameterize';
import SearchParameterize from './searchParameterize';

import { cleanData, setListParameterization } from '../../../../../../reducers/typePeople/reducerParameterize';
import { setSelectedTypepeople } from '../../../../../../reducers/typePeople/reducerTypepeople';
import { setCategory, setSelectedCategory } from '../../../../../../reducers/typePeople/reducerCategory';
import serviceGroup from "../service/serviceGroupCategory";
import serviceCategory from "../../service/serviceCategory";
import { ID_NOT_DEFINED } from '../../../../../../constant/constant';
import { setSelectedGroup } from '../../../../../../reducers/typePeople/reducerGroupCategory';
import BreadcrumbCustom from '../../../../../../app/components/BreadcrumCustom';

const TypepeopleCategoryGroupParameterize = () => {
    const { list: listTypePeople, selectedTypepeople } = useSelector(state => state.typepeopleSlice);
    const { list: listCategory, selectedCategory } = useSelector(state => state.typepeopleCategorySlice);
    const { selectedGroup } = useSelector(state => state.typepeopleCategoryGroupSlice);
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const { people = 0, category = 0, cluster = 0 } = queryString.parse(search);

    const loadData = async (typePeopleId, categoryId, cluster) => {
        if (typePeopleId === ID_NOT_DEFINED || cluster === ID_NOT_DEFINED) {
            history.push("/typepeople/categories");
        }
        dispatch(setSelectedTypepeople({ value: listTypePeople.find(i => i.id === parseInt(people)) }));

        if (listCategory.length === 0) {
            const { categories = [] } = await serviceCategory.getList(typePeopleId);
            dispatch(setCategory({ value: categories }));
            dispatch(setSelectedCategory({ value: categories.find(i => i.id === parseInt(categoryId)) }));
        }

        const res = await serviceGroup.getById(cluster);
        dispatch(setSelectedGroup({ value: res }));
        const { parameterizations = [] } = res;
        dispatch(setListParameterization({ value: parameterizations }));
    }

    useEffect(() => {
        loadData(people, category, cluster);
    }, [listTypePeople, people, category, cluster]);

    const handleCreate = () => {
        dispatch(cleanData());
        history.push({
            pathname: "/typepeople-categories-groups/create-parameterize",
            search: `?people=${people}&category=${category}&cluster=${cluster}`,
        });
    }

    return (
        <>
            <div className="mx-4">
                <BreadcrumbCustom
                    title="Campos"
                    routes={[
                        { name: "Tipos de persona", link: "/typepeople/list" },
                        { name: selectedTypepeople.name ? selectedTypepeople.name : "Seleccione tipo de persona", link: "/typepeople/list" },
                        { name: selectedCategory.name ? selectedCategory.name : "Seleccione catergoría", link: "/typepeople/categories" },
                        { name: selectedGroup.name ? selectedGroup.name : "Seleccione grupo", link: `/typepeople-categories/groups?people=${selectedTypepeople.id}&category=${selectedCategory.id}` },
                    ]}
                    activeRoute="Listado campos"
                />

                <div className='container m-0 mt-2 p-0'>
                    <div className='row'>
                        <div className='col-6 p-0'>
                            <SearchParameterize />
                        </div>
                        <div className='col-6 p-0'>
                            <button title="Crear nuevo campo" className="btn btn-success" type="button" onClick={handleCreate}>
                                <i className="fa-solid fa-plus text-white me-2" style={{ fontSize: '16px' }} />
                                Crear campo
                            </button>
                        </div>
                    </div>
                </div>
                <div className="table-data" >
                    <CategoryGroupListParameterize />
                </div>
            </div>
        </>
    );

}

export default TypepeopleCategoryGroupParameterize;