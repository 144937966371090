import React, { useState } from "react";
import { Modal } from "./index";
import GeneralService from "../../../services/GeneralService";
import { useDispatch, useSelector } from "react-redux";
import { MessageError, toast } from "../../../utils";
import { ALERT_SUCCESS } from "../../../constant/constant";
import {
  setEventUpdate,
} from "../../../reducers/calendar/reducerCalendar";
import Select from "react-select";

const Modal3 = ({ getEvents ,setUpdateEvent }) => {
  const [modal3, setModal3] = useState(false);
  const { labels , user, eventupdate, filters } =
    useSelector((state) => state.calendarslice);
  const [selectedLabel, setSelectedLabel] = useState(null);

  const dispatch = useDispatch();


  const handleLabelClick = (e, labelid) => {
    e.preventDefault();
    setSelectedLabel(labelid);
    console.log(labelid);
    dispatch(setEventUpdate({ key: "labels_id", value: [labelid] }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "start_date" || name === "end_date") {
      // Convertir el valor del input datetime-local a UTC ISO 8601
      const localDate = new Date(value);
      const utcDate = new Date(
        localDate.getTime() - localDate.getTimezoneOffset() * 60000
      );
      const isoString = utcDate.toISOString();

      console.log("soy name fecha ", name, "soy value fecha ", isoString);
      dispatch(setEventUpdate({ key: name, value: isoString }));
    } else {
      console.log("soy name ", name, "soy value ", value);
      dispatch(setEventUpdate({ key: name, value }));
    }
  };

  const handleSubmit = async (e, id) => {
    e.preventDefault();
    const service = new GeneralService(`events`);
    const res = await service.update({ Event: eventupdate , id : eventupdate.id });
    console.log("soy id ",id,"soy evento ",eventupdate);
    if (res.is_ok) {
      toast(dispatch, res.message, ALERT_SUCCESS, 5000);
      getEvents();
      setModal3(false);
      return;
    }
    MessageError(res.message);
    setModal3(false);
  };

  const formatOptionLabel = ({ avatar, label, email }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={avatar}
        alt={label}
        style={{ width: 30, height: 30, borderRadius: "50%", marginRight: 10 }}
      />
      <div>
        <div>{label}</div>
        <div style={{ fontSize: "12px", color: "#888" }}>{email}</div>
      </div>
    </div>
  );
  const handleDeletevents = async (id) => {
    const confirmed = window.confirm(
      "¿Estás seguro de que quieres eliminar este eventos?"
    );

    if (confirmed) {
      console.log("Elemento con ID: ", id, " eliminado");
      const service = new GeneralService(`events`);
      const object = await service.delete(id);
      getEvents();
    }
  };

  const handleSelectChangemodal3 = (selectedOption, actionMeta) => {
    if (actionMeta.name === "responsible_id") {
      console.log("soy id ", selectedOption.value);
      dispatch(
        setEventUpdate({ key: "responsible_id", value: selectedOption.value })
      );
    } else if (actionMeta.name === "reporting_id") {
      console.log("soy id ", selectedOption.value);
      dispatch(setEventUpdate({ key: "reporting_id", value: selectedOption.value }));
    }
  };
  const options = user.map((item, index) => ({
    value: item.user_id,
    label: `${index} - ${item.name}`, // solo el texto para el label
    avatar: item.url_avatar, // URL del avatar
    email: item.user.email, // Email
  }));

  
  return (
    <div className="botoncontainertool">
      <button className="botonmodal" onClick={() =>{
        setUpdateEvent()
        setModal3(!modal3)
      } }>
        Modificar
      </button>
      <Modal
        State={modal3}
        setModal3={setModal3}
        titulo={"Modificar el evento"}
        showHeader={true}
        showOverlay={true}
      >
        <form className="formu" onSubmit={(e) => handleSubmit(e, eventupdate.id)}>
          <div className="containermodal2">
            <div className="labelsmodal2">
              {labels.map((item, index) => (
                <button
                  id={item.id}
                  key={index}
                  className={`btndrop ${
                    selectedLabel === item.name ? "selected" : ""
                  }`}
                  style={{
                    color: item.label_color.color_font,
                    backgroundColor: item.label_color.color,
                    border:
                      selectedLabel === item.name ? "1px solid black" : "none",
                  }}
                  onClick={(e) => handleLabelClick(e, item.id)}
                >
                  {item.name}
                </button>
              ))}
              <div className="divselecthour">
                <select
                  name="notification_time"
                  className="btn btn-secondary dropdown-toggle"
                  onChange={handleChange}
                  required
                  value={eventupdate.notification_time}
                >
                  <option  className="dropdown-item" value="" disabled>
                    intervalo
                  </option>
                  <option className="dropdown-item" value="15M">
                    15M
                  </option>
                  <option className="dropdown-item" value="30M">
                    30M
                  </option>
                  <option className="dropdown-item" value="1H">
                    1H
                  </option>
                  <option className="dropdown-item" value="5H">
                    5H
                  </option>
                  <option className="dropdown-item" value="1D">
                    1D
                  </option>
                  <option className="dropdown-item" value="1W">
                    1W
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="divmodal2body">
            <div className="divinput">
              <i className="fa-solid fa-file-pen icomodal" />
              <input
                className="input-n2"
                type="text"
                placeholder="Titulo del evento"
                name="title"
                value={eventupdate.title}
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </div>
            <div className="divinput">
              <i className="fa-solid fa-user-plus" />
              <Select
                value={options.find(
                  (option) => option.value === eventupdate.responsible_id
                )} // Mostrar la opción seleccionada basada en el ID
                onChange={(selectedOption) =>
                  handleSelectChangemodal3(selectedOption, {
                    name: "responsible_id",
                  })
                }
                options={options}
                formatOptionLabel={formatOptionLabel}
                name="responsible_id"
                placeholder="responsable del evento"
              />
              <Select
                value={options.find(
                  (option) => option.value === eventupdate.reporting_id
                )} // Mostrar la opción seleccionada basada en el ID
                onChange={(selectedOption) =>
                  handleSelectChangemodal3(selectedOption, {
                    name: "reporting_id",
                  })
                }
                options={options}
                formatOptionLabel={formatOptionLabel}
                name="reporting_id"
                placeholder="informador del evento"
              />
            </div>
            <div className="divinput">
              <i className="fa-solid fa-clock"></i>
              <input
                className="input-n2"
                type="datetime-local"
                name="start_date"
                value={eventupdate.start_date ? eventupdate.start_date.slice(0, 16) : ''}
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </div>
            <div className="divinput">
              <i className="fa-solid fa-clock"></i>
              <input
                className="input-n2"
                type="datetime-local"
                name="end_date"
                value={eventupdate.end_date ? eventupdate.end_date.slice(0, 16) : ''}
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </div>
            <div className="divinput">
              <i className="fa-solid fa-align-justify"></i>
              <textarea
                className="input-n2"
                placeholder="Agrega una descripción..."
                name="description"
                onChange={handleChange}
                required
                value={eventupdate.description}
                autoComplete="off"
                style={{
                  width: "713px",
                  height: "160px",
                  padding: "10px",
                }}
              />
            </div>
          </div>
          <div className="divbotones">
            <button type="submit" className="botonmodal">
              Guardar
            </button>
            <button
              type="button"
              className="botonmodal2"
              onClick={() => setModal3(false)}
            >
              Cancelar
            </button>
          </div>
        </form>
      </Modal>
      <button
        className="botonmodal2"
        onClick={() => handleDeletevents(eventupdate.id)}
      >
        Eliminar
      </button>
    </div>
  );
};

export default Modal3;
