import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { MessageError, MessageSuccess } from '../../../../utils/message';
import service from "./service/serviceCategory";
import { setSelectedCategory, deleteCategory } from '../../../../reducers/typePeople/reducerCategory';
import confirmDelete from '../../../../utils/confirmDelete';
import IconButton from '../../../../app/components/IconButton';

export const ListCategory = ({ setShow }) => {
    const { listFilter } = useSelector(state => state.typepeopleCategorySlice);
    const dispatch = useDispatch();
    const history = useHistory();

    const deleteCat = async (id) => {
        const res = await service.delete(id);
        if (res.is_ok) {
            dispatch(deleteCategory({ value: id }));
            MessageSuccess(res.message);
            return;
        }
        MessageError(res.message, service.status);
    }

    const handleGroups = (item) => {
        dispatch(setSelectedCategory({ value: item }));
        history.push({
            pathname: "/typepeople-categories/groups",
            search: `?people=${item.type_peoples_id}&category=${item.id}`
        });
    }

    return (
        <>
            <div className="order">
                {
                    listFilter.length === 0 ?
                        <div className="alert alert-warning text-center mt-2" role="alert">
                            No hay categorías que mostrar
                        </div>
                        :
                        <div className="d-block rounded-3 clip-hide">
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>#</th>
                                        <th>Nombre </th>
                                        <th>Descripción</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFilter.map((item, i) => (
                                            <tr key={item.id}>
                                                <td></td>
                                                <td>{i + 1}.</td>
                                                <td>{item.name}</td>
                                                <td>{item.description}</td>
                                                <td>
                                                    <IconButton
                                                        icon="fa-solid fa-pen-to-square text-green"
                                                        title="Editar categoría"
                                                        onClick={() => {
                                                            dispatch(setSelectedCategory({ value: item }));
                                                            setShow(true);
                                                        }}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-trash-can text-red"
                                                        title="Eliminar categoría"
                                                        onClick={async () => await confirmDelete(() => deleteCat(item.id))}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-layer-group text-purple"
                                                        title="Ver grupos"
                                                        onClick={() => handleGroups(item)}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </div>
        </>
    )

}
