import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import IconButton from '../../../../app/components/IconButton';
import { Color } from '../../../../app/components/Color';

import GeneralService from '../../../../services/GeneralService';
import { useAnimateBackground } from '../../../../app/hooks/useAnimateBackground';
import { deleteFromMovement, orderMovement, setList, setSelected } from '../../../../reducers/expedient/reducerTypeProcessMovement';
import { MessageError, MessageSuccess, cutText } from '../../../../utils';
import { showAnimateOrderItem } from '../../../../utils/showAnimateOrderItem';
import confirmDelete from '../../../../utils/confirmDelete';

export const ListTypeMovement = ({ setShow, typeProcess }) => {
    const { listFilter } = useSelector(state => state.typeProcessMovementSlice);
    const { filtered } = useSelector(state => state.documentsAutoSlice);
    const { list } = useSelector(state => state.colorsSlice);
    const dispatch = useDispatch();
    const [showEffect, setShowEffect] = useAnimateBackground();

    const loadData = async () => {
        const service = new GeneralService("type-process-movement");
        const res = await service.getList(1000, { type_process: typeProcess });
        const { movements = [] } = res;
        dispatch(setList(movements));
    }

    const deleteMovement = async (id) => {
        const service = new GeneralService("type-process-movement");
        const res = await service.delete(id);
        if (res.is_ok) {
            MessageSuccess("El registro ha sido eliminado exitosamente!");
            dispatch(deleteFromMovement(id));
            return;
        }
        MessageError("No fue posible eliminar la parametrización");
    }

    const handleMove = async (id, order) => {
        const service = new GeneralService("type-process-movement/order");
        const res = await service.post({ id, order });
        if (res.is_ok) {
            dispatch(orderMovement({ value: { id, order } }));
            setShowEffect({ show: true, id });
            return;
        }
        MessageError(res.message, service.status);
    }

    useEffect(() => {
        loadData();
    }, [typeProcess]);

    return (
        <>
            <div className="order">
                {
                    listFilter.length === 0 ?
                        <div className="alert alert-warning text-center mt-2" role="alert">
                            No hay movimientos que mostrar
                        </div>
                        :
                        <div className="d-block rounded-3 clip-hide">
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr className='font-lato'>
                                        <th></th>
                                        <th>#</th>
                                        <th>Color</th>
                                        <th>Nombre</th>
                                        <th>Descripción</th>
                                        <th>Días</th>
                                        <th>Documento</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFilter.map((item, i) => (
                                            <tr
                                                key={item.id}
                                                className={`font-rubik change-background-animation ${showAnimateOrderItem(item, showEffect)}`}
                                                onDoubleClick={() => { dispatch(setSelected(item)); setShow(true); }}
                                            >
                                                <td></td>
                                                <td>{i + 1}.</td>
                                                <td style={{width: "30px"}}>
                                                    <Color size={25} color={list.find(itemColor => itemColor.id === item.colors_id)?.color} />
                                                </td>
                                                <td>{cutText(item.name, 40)}</td>
                                                <td>{cutText(item.description, 40)}</td>
                                                <td>{item.wait_time_days}</td>
                                                <td>{cutText(filtered.find(document => document.id === item.documents_auto_id)?.name, 40)}</td>
                                                <td>
                                                    <IconButton
                                                        icon="fa-solid fa-pen-to-square text-green"
                                                        title="Editar movimiento"
                                                        onClick={() => { dispatch(setSelected(item)); setShow(true); }}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-trash-can text-red"
                                                        title="Eliminar movimiento"
                                                        onClick={async () => await confirmDelete(() => deleteMovement(item.id))}
                                                    />
                                                    <div className="move-parameterization">
                                                        <i className="fa-solid fa-circle-arrow-up text-green me-2" role='button' onClick={() => handleMove(item.id, (i - 1))}></i>
                                                        <i className="fa-solid fa-circle-arrow-down text-purple" role='button' onClick={() => handleMove(item.id, (i + 1))}></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </div>
        </>
    )
}