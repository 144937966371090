import useCreateCase from "../hooks/useCreateCase";

import Selectors from "./Selectors";
import CaseClusters from "./CaseClusters";
import BreadcrumbCustom from '../../../../app/components/BreadcrumCustom';
import AccordionCategory from './AccordionCategory';

const FormCreateCase = () => {
    const { id, saveData, fieldsInvalid, encodeDocument, selectedCase, listCategories } = useCreateCase();

    return (
        <div className="container-fluid">
            <div className="row">
                <form onSubmit={saveData} onInvalid={(e) => fieldsInvalid(e)}>
                    <BreadcrumbCustom
                        title={id === 0 ? 'Crear Caso' : 'Editar Caso'}
                        routes={[
                            { name: "Casos", link: "/case/list" },
                        ]}
                        activeRoute={id === 0 ? 'Crear Caso' : 'Editar Caso'}
                    />
                    <Selectors id={id} />
                    <CaseClusters
                        encodeDocument={encodeDocument}
                        id={id}
                    />
                    {
                        listCategories?.length !== 0 &&
                        <>
                            <h4 style={{ textAlign: 'center', marginBottom: '15px', marginTop: '2.2rem' }}>
                                <strong>CATEGORÍAS</strong>
                            </h4>
                            {
                                listCategories?.map((category) => <AccordionCategory
                                    key={category.id}
                                    category={category}
                                    encodeDocument={encodeDocument}
                                    id={id}
                                />)
                            }
                        </>
                    }
                    {
                        Object.keys(selectedCase).length !== 0 && selectedCase.clusters?.length !== 0 &&
                        <div className="row">
                            <div className="col">
                                <button type="submit"
                                    className="btn btn-success d-block rounded-3 ms-auto mt-3 px-3 py-2">
                                    <i className="fa-solid fa-paper-plane me-2"></i> Enviar
                                </button>
                            </div>
                        </div>
                    }
                </form >
            </div>
        </div >
    );

}
export default FormCreateCase;