import React, { useState } from "react";
import { Modal ,ColorPicker} from "./index";
import GeneralService from "../../../services/GeneralService";
import { useDispatch, useSelector } from "react-redux";
import { MessageError, toast } from "../../../utils";
import { ALERT_SUCCESS } from "../../../constant/constant";
import {
  setListLabels,
  setLabel,
  setCleanLabel,

} from "../../../reducers/calendar/reducerCalendar";

const Modal1 = () => {
  const [modal1, setModal1] = useState(false);
  const dispatch = useDispatch();
  const { label} = useSelector(
    (state) => state.calendarslice
  );

  //Labelsubmit
  const labelSubmit = async (e) => {
    e.preventDefault();
    const service = new GeneralService("labels");
    const res = await service.post(label);
    if (res.is_ok) {
      toast(dispatch, res.message, ALERT_SUCCESS, 5000);
      getLabels();
      dispatch(setCleanLabel());
      setModal1(false);
      return;
    }
    MessageError(res.message);
  };

  const getLabels = async () => {
    const service = new GeneralService("labels");
    const object = await service.getList(1000);
    const labels = object.labels;
    dispatch(setListLabels({ value: labels }));
  };

  //agregar etiqueta
  const SetdataLabels = (e) => {
    const data = {
      key: e.target.name,
      value: e.target.value,
    };
    dispatch(setLabel(data));
  };
  return (
    <div className="div-a ">
      <a className="a2" onClick={() => setModal1(!modal1)}>
        <i className="fa-solid fa-circle-plus fs-5 mx-1 icon2" />
      </a>
      <Modal
        State={modal1}
        setModal1={setModal1}
        titulo={"Crear una nueva etiqueta "}
        showHeader={true}
        showOverlay={true}
        modalType="modal2"
      >
        <form className="formu" onSubmit={labelSubmit}>
          <div className="divinput">
            <i className="fa-solid fa-file-pen icomodal"></i>
            <input
              className="input-n"
              type="text"
              placeholder="Nombre de la categoría"
              name="name"
              value={label.name}
              onChange={(event) => SetdataLabels(event)}
              required
              autoComplete="off"
            />
          </div>

          <ColorPicker className="color-pick" />
          <div className="divbotones">
            <button type="submit" className="botonmodal">
              Guardar
            </button>
            <button className="botonmodal2" onClick={() => setModal1(false)}>
              cancelar
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Modal1;
