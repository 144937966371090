import BreadcrumbCustom from "../../../app/components/BreadcrumCustom";
import DynamicTable from "./DynamicTable";
import SearchBarCase from "./SearchBarCase";
import { SelectAndCreate } from "./SelectAndCreate";

const CreateCase = () => {
    return (
        <div className="container-fluid max-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title="Casos"
                        routes={[
                            { name: "Casos", link: "/case/list" },
                        ]}
                        activeRoute="Listar Casos"
                    />
                </div>
            </div>
            <div className='container-fluid'>
                <div className="table-data">
                    <div className='row'>
                        <SelectAndCreate />
                    </div>
                    <div className='row mt-3'>
                        <SearchBarCase />
                    </div>
                    <div className='row'>
                        <DynamicTable actionName="Acciones" />
                    </div>
                </div>
            </div>
        </div >
    )
};

export default CreateCase;