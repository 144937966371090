import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import IconButton from '../../../../../../app/components/IconButton';

import { deleteParameterization, orderParameterization } from '../../../../../../reducers/legalCase/reducerParameterizationCategory';
import confirmDelete from '../../../../../../utils/confirmDelete';
import { MessageError, MessageSuccess } from '../../../../../../utils/message';
import GeneralService from '../../../../../../services/GeneralService';

const ListParameterizationCategory = () => {
    const { listFilter } = useSelector(state => state.parameterizationCategoryLegalCaseSlice);
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const { legalCase = 0 } = queryString.parse(search);

    const deleteParam = async (id) => {
        const service = new GeneralService("parameterization-category-legal-case");
        const res = await service.delete(id);
        if (res.is_ok) {
            MessageSuccess(res.message);
            dispatch(deleteParameterization({ value: id }));
            return;
        }
        MessageError(res.message, service.status);
    }
    
    const handleEdit = (item) => {
        history.push({
            pathname: `/case-parameterization/category/fields/update/${item.id}`,
            search: `?legalCase=${legalCase}&category=${item.category_legal_case_id}&cluster=${item.cluster_category_legal_case_id}`,
        });
    }

    const handleMove = async (id, order, is_category) => {
        const service = new GeneralService("parameterization-legal-case/order");
        const res = await service.post({ id, order, is_category });
        if (res.is_ok) {
            dispatch(orderParameterization({ value: { id, order } }));
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <>
            <div className="order">
                {
                    listFilter.length === 0 ?
                        <div className="alert alert-warning text-center mt-2" role="alert">
                            No hay campos que mostrar
                        </div>
                        :
                        <div className="d-block rounded-3 clip-hide">
                            <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter.length > 0 && "table-striped"}`}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>#</th>
                                        <th>Campo</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listFilter.map((item, i) => (
                                            <tr key={item.id}>
                                                <td></td>
                                                <td>{i + 1}.</td>
                                                <td>{item.name}</td>
                                                <td>
                                                    <IconButton
                                                        icon="fa-solid fa-pen-to-square text-green"
                                                        title="Editar parametrización"
                                                        onClick={() => handleEdit(item)}
                                                    />
                                                    <IconButton
                                                        icon="fa-solid fa-trash-can text-red"
                                                        title="Eliminar parametrización"
                                                        onClick={async () => await confirmDelete(() => deleteParam(item.id))}
                                                    />
                                                    <div className="move-parameterization">
                                                        <i className="fa-solid fa-circle-arrow-up text-green me-2" role='button' onClick={() => handleMove(item.id, (i - 1), true)}></i>
                                                        <i className="fa-solid fa-circle-arrow-down text-purple" role='button' onClick={() => handleMove(item.id, (i + 1), true)}></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </div>
        </>
    )

}

export default ListParameterizationCategory;

/* import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Table, { TableHeadItem, TablesRow } from "../../../../../../app/components/OptimimizedTable"

import { MessageError, MessageSuccess } from "../../../../../../utils/message"
import ButtonEdit from "../../../../../../app/components/EditButton"
import ButtonRemove from "../../../../../../app/components/RemoveButton"

import { setListClusters, deleteCategoryGroupParametrize } from "./reducer/reducerParameterize";
import service from './service/serviceParametrizacion';
import serviceCategoryGroups from '../service/serviceGroupCategory';

const CategoryGroupListParameterize = () => {
    const listParameterize = useSelector(state => state.typepeopleCategoryGroupParameterize.listFilter);
    const dispatch = useDispatch();

    useEffect(() => {
        getClustersParameterize(id);
    }, []);

    const getClustersParameterize = async (id) => {
        const data = await serviceCategoryGroups.geyById(id);
        const { parameterizations_category_people = [] } = data;
        dispatch(setListClusters({
            value: parameterizations_category_people
        }));
    }

    const confirmDelete = async (id) => {
        const res = await service.delete(id);
        if (res.is_ok) {
            MessageSuccess(res.message)
            dispatch(deleteCategoryGroupParametrize({ value: id }));
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <>
            <div className="order">
                {
                    listParameterize == null
                        ?
                        null
                        :
                        listParameterize?.length <= 0
                            ?
                            <div className="alert alert-warning" role="alert" style={{ marginBottom: '0px' }}>
                                ¡No hay parametrizaciones para mostrar!
                            </div>
                            :
                            <Table
                                header={
                                    <>
                                        <TableHeadItem name="#" />
                                        <TableHeadItem name="Nombre de la parametrización" />
                                        <TableHeadItem name="Acciones" />
                                    </>
                                }
                                body={
                                    listParameterize.map((item, i) => (
                                        <TablesRow
                                            key={item.id}
                                            children={
                                                <>
                                                    <td></td>
                                                    <td>{i + 1}.</td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        <ButtonEdit id={item.id} ruta={"/typepeople-categories-groups/edit-parameterize/"} />
                                                        <ButtonRemove confirm={() => confirmDelete(item.id)} />
                                                    </td>
                                                </>
                                            }
                                        />
                                    ))
                                }
                            />
                }
            </div>
        </>
    );
}

export default CategoryGroupListParameterize; */