import { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from 'query-string';

import BreadcrumbCustom from "../../app/components/BreadcrumCustom";
import CardConfiguration from "./CardConfiguration";
import TabFavoriteCodes from "./TabFavoriteCodes";
import TabCodesGlobal from "./TabCodesGlobal";
import TabLabelGlobal from "./TabLabelGlobal";
import TabZapsignConfiguration from "./TabZapsignConfiguration";
import TabColors from "./TabColors";
import { TabExpedients } from "./TabExpedients";

import "./style/configuration.css";

const ConfigurationView = () => {
    const { search } = useLocation();
    const { tabIndex = 1 } = queryString.parse(search);
    const [selected, setSelected] = useState(parseInt(tabIndex));

    return (
        <div className="container-fluid max-height-overflow-y">
            <div className="row">
                <div className="col">
                    <BreadcrumbCustom
                        title="Configuraciones"
                        activeRoute="/ Configuraciones"
                    />
                </div>
            </div>

            <div className="row px-3">
                <div className="card rounded-4 py-3">
                    <div className="row">
                        <div className="col-4 col-lg-3">
                            <CardConfiguration
                                icon="bxs-user"
                                title="Códigos favoritos"
                                subtitle="Personas y Casos"
                                selected={selected}
                                order={1}
                                icon_style="text-white"
                                iconBackground="bg-blue"
                                onClick={() => setSelected(1)}
                            />
                            <CardConfiguration
                                icon="bxs-calendar-check"
                                title="Códigos globales"
                                subtitle="Globales"
                                selected={selected}
                                order={2}
                                icon_style="text-white"
                                iconBackground="bg-green"
                                onClick={() => setSelected(2)}
                            />
                            <CardConfiguration
                                icon="bxs-edit-alt"
                                title="Firma electrónica"
                                subtitle="Zapsign"
                                selected={selected}
                                order={3}
                                icon_style="text-white"
                                iconBackground="bg-orange"
                                onClick={() => setSelected(3)}
                            />
                            <CardConfiguration
                                icon="bxs-label"
                                title="Etiquetas globales"
                                subtitle="Documentos"
                                selected={selected}
                                order={4}
                                icon_style="text-white"
                                iconBackground="bg-purple300"
                                onClick={() => setSelected(4)}
                            />
                            <CardConfiguration
                                icon="bxs-palette"
                                title="Colores"
                                subtitle="Estatus"
                                selected={selected}
                                order={5}
                                icon_style="text-white"
                                iconBackground="bg-fucsia"
                                onClick={() => setSelected(5)}
                            />
                            <CardConfiguration
                                icon="bxs-folder"
                                title="Expedientes"
                                subtitle="Grupos y notificaciones"
                                selected={selected}
                                order={6}
                                icon_style="text-white"
                                iconBackground="bg-blue"
                                onClick={() => setSelected(6)}
                            />
                        </div>
                        <div className="col px-3 py-4" style={{maxHeight: "75vh", overflowY: "auto" }}>
                            {selected === 1 && <TabFavoriteCodes />}
                            {selected === 2 && <TabCodesGlobal />}
                            {selected === 3 && <TabZapsignConfiguration />}
                            {selected === 4 && <TabLabelGlobal />}
                            {selected === 5 && <TabColors />}
                            {selected === 6 && <TabExpedients />}
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default ConfigurationView;