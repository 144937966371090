import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import ListParametrizar from './listParameterize';
import SearchParameterize from './searchParameterize';
import BreadcrumbCustom from "../../../../../app/components/BreadcrumCustom";

import GeneralService from "../../../../../services/GeneralService";
import { setSelectedTypepeople } from "../../../../../reducers/typePeople/reducerTypepeople";
import { cleanData, setListParameterizationsOfClusterById } from "../../../../../reducers/typePeople/reducerParametrizacion";
import { setSelectedGroup } from "../../../../../reducers/typePeople/reducerGroup";

const TypepeopleGroupParameterize = () => {
    const { list, selectedTypepeople } = useSelector(state => state.typepeopleSlice);
    const { selectedGroup } = useSelector(state => state.typepeopleGruopSlice);
    const history = useHistory();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const { people = 0, cluster = 0 } = queryString.parse(search);

    const loadData = async (id) => {
        const service = new GeneralService("clusters/"+id);
        dispatch(setSelectedTypepeople({ value: list.find(i => i.id === parseInt(people)) }));

        const res = await service.getRequest();
        if (res.name) {
            dispatch(setSelectedGroup({ value: res }));
            const { parameterizations = [] } = res;
            dispatch(setListParameterizationsOfClusterById({ value: parameterizations }));
        } else {
            history.push("/typepeople/groups");
        }
    }

    useEffect(() => {
        loadData(cluster);
    }, [cluster, people, list]);


    const handleCreate = () => {
        dispatch(cleanData());
        history.push({
            pathname: "/typepeople/create-parameterize",
            search: `?people=${people}&cluster=${cluster}`,
        });
    }

    return (
        <div className='mx-4'>
            <BreadcrumbCustom
                title="Campos"
                routes={[
                    { name: "Tipos de persona", link: "/typepeople/list" },
                    { name: selectedTypepeople.name ? selectedTypepeople.name : "Seleccione tipo de persona", link: "/typepeople/list" },
                    { name: selectedGroup.name ? selectedGroup.name : "Grupo no seleccionado", link: `/typepeople/groups?people=${selectedTypepeople.id}` },
                ]}
                activeRoute="Listar campos"
            />

            <div className='container m-0 mt-2 p-0'>
                <div className='row'>
                    <div className='col-6 p-0'>
                        <SearchParameterize />
                    </div>
                    <div className='col-6 p-0'>
                        <button title="Crear nuevo campo" className="btn btn-success" type="button" onClick={handleCreate}>
                            <i className="fa-solid fa-plus text-white me-2" style={{ fontSize: '16px' }} />
                            Crear campo
                        </button>
                    </div>
                </div>
            </div>
            <div className="table-data" >
                <ListParametrizar />
            </div>
        </div>
    )
}

export default TypepeopleGroupParameterize;