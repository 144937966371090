import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedCategory, setListCategories } from '../../../../reducers/createCase/reducerCreateCase';
import { ID_NOT_DEFINED } from '../../../../constant/constant';
import { setSelectedCase } from '../../../../reducers/legalCase/reducerLegalCase';

const Selectors = ({ id }) => {
    const { selectedCase, list } = useSelector(state => state.legalCaseSlice);
    const { selectedCategory, listCategories } = useSelector(state => state.createCaseSlice);
    const dispatch = useDispatch();
    const [showSelectedCategory, setShowSelectedCategory] = useState(false);

    const setCategoryToList = () => {
        if (Object.keys(selectedCategory).length === 0) return;
        dispatch(setListCategories());
        setShowSelectedCategory(false);
    }

    return (
        <>
            <div className="container-fluid m-0 p-0">
                <div className="row m-0 p-0">
                    <div className="col-3">
                        <select className="form-select"
                            value={selectedCase.id}
                            onChange={(e) => {
                                const id = parseInt(e.target.value);
                                dispatch(setSelectedCase({ value: list.find(legalCase => legalCase.id === id) }));
                            }}
                            disabled={id !== ID_NOT_DEFINED}
                        >
                            <option value=''>
                                Seleccione el tipo de caso
                            </option>
                            {
                                list.map((item) => {
                                    return (
                                        <option
                                            key={item.id}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </option>
                                    )
                                })
                            }

                        </select>
                    </div>

                    {
                        selectedCase.id !== 0 &&
                        <>
                            <div className='col-1' style={{ minWidth: '155px' }}>
                                <button type="button" className='btn btn-success rounded-3 button-add-category'
                                    onClick={() => setShowSelectedCategory(true)}
                                    disabled={
                                        !(selectedCase.categories)
                                    }
                                >
                                    <i className="fa-solid fa-plus" style={{ marginRight: '6px' }}></i>
                                    Categoria
                                </button>
                            </div>

                            {
                                (showSelectedCategory && selectedCase.categories) &&
                                <>
                                    {/* Selector de las categorías */}
                                    <div className="col-3">
                                        <select className="form-select"
                                            defaultValue={'default'}
                                            value={selectedCategory.id}
                                            onChange={(e) => {
                                                const id = parseInt(e.target.value);
                                                dispatch(setSelectedCategory({ value: selectedCase.categories.find(category => category.id === id) }));
                                            }}
                                        >
                                            <option value="">
                                                Seleccione la categoría
                                            </option>
                                            {
                                                selectedCase.categories?.map((category) => {
                                                    return (
                                                        <option value={category.id} key={category.id}
                                                            disabled={
                                                                listCategories.length > 0 &&
                                                                listCategories.findIndex(i => i.id === category.id) !== -1
                                                            }
                                                        >
                                                            {category.name}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-1'>
                                        <button type="button" className={`btn btn-success button-confirm-add-category rounded-3`}
                                            onClick={setCategoryToList}
                                            disabled={Object.keys(selectedCategory).length === 0}
                                        >
                                            Listo
                                        </button>
                                    </div>
                                    <div className="col-2"></div>
                                </>
                            }
                        </>

                    }



                </div>
            </div>
        </>
    )
}

export default Selectors;
