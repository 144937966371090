import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';

import BreadcrumbCustom from '../../../../../../app/components/BreadcrumCustom';
import SelectControl from '../../../SelectControl';

import { reducerForm, addParameterization, setDataGetById, nullLengths, setListParameterization } from '../../../../../../reducers/legalCase/reducerParameterizationCategory';
import { setSelectedCluster } from '../../../../../../reducers/legalCase/reducerClusterCategory';
import { setListCategory, setSelectedCategory } from '../../../../../../reducers/legalCase/reducerCategoryLegalCase';
import { FIELD_FILE, FIELD_SELECT, ID_NOT_DEFINED } from '../../../../../../constant/constant';
import { MessageError, MessageSuccess } from '../../../../../../utils/message';
import { setSelectedCase } from '../../../../../../reducers/legalCase/reducerLegalCase';
import GeneralService from '../../../../../../services/GeneralService';

const FormParameterizationCategoryLegalCase = () => {
    const { list: listCases, selectedCase } = useSelector(state => state.legalCaseSlice);
    const { list: listCategory, selectedCategory } = useSelector(state => state.categoryLegalCaseSlice);
    const { selectedCluster } = useSelector(state => state.clusterCategoryLegalCaseSlice);
    const { parameterization } = useSelector(state => state.parameterizationCategoryLegalCaseSlice);
    const dispatch = useDispatch();
    const history = useHistory();
    let { id = 0 } = useParams();
    const { search } = useLocation();
    const { legalCase = 0, category = 0, cluster = 0 } = queryString.parse(search);

    const loadData = async () => {
        if (category === ID_NOT_DEFINED || cluster === ID_NOT_DEFINED) {
            history.push("/case-parameterization/category");
        }
        dispatch(setSelectedCase({ value: listCases.find(i => i.id === parseInt(legalCase)) }));

        if (listCategory.length === 0) {
            const service = new GeneralService("category-legal-case");
            const { categories = [] } = await service.getList(1000, { legal_case_id: legalCase });
            dispatch(setListCategory({ value: categories }));
            dispatch(setSelectedCategory({ value: categories.find(i => i.id === parseInt(category)) }));
        }

        const service = new GeneralService("cluster-category-legal-case/"+cluster);
        const res = await service.getRequest();
        dispatch(setSelectedCluster({ value: res }));
        const { parameterizations = [] } = res;
        dispatch(setListParameterization({ value: parameterizations }));
    }

    useEffect(() => {
        loadData();
    }, [listCases, legalCase, category, cluster]);

    const field = parseInt(parameterization.type_fields_id);
    const required = field === 3 || field === 4 || field === 5 || field === 6 || field === 7 || field === 9;

    useEffect(() => {
        if (required) {
            dispatch(nullLengths());
        }
    }, [required]);

    const setData = (e) => {
        dispatch(reducerForm({
            key: e.target.name,
            value: e.target.value,
        }));
    }

    const getParameterization = async (id) => {
        if (id !== ID_NOT_DEFINED) {
            const service = new GeneralService("parameterization-category-legal-case/"+id);
            const res = await service.getRequest();
            if (Object.keys(res).length > 0) {
                dispatch(setDataGetById({ value: res }));
                return;
            }
            MessageError("La parametrización no existe");
            history.push("/case-parameterization/category/cluster");
            return;
        }
        if (category === ID_NOT_DEFINED || cluster === ID_NOT_DEFINED) history.push("/case-parameterization/category/cluster");
    }

    useEffect(() => {
        getParameterization(id);
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();        
        const service = new GeneralService("parameterization-category-legal-case");

        const data = {
            id: parameterization.id,
            category_legal_case_id: parseInt(category) ?? 0,
            cluster_category_legal_case_id: parseInt(cluster) ?? 0,
            name: parameterization.name,
            type_fields_id: parseInt(parameterization.type_fields_id) ?? 0,
            length_min: parseInt(parameterization.length_min) ?? 0,
            length_max: parseInt(parameterization.length_max) ?? 0,
            required: parameterization.required.toString() === "true",
            value_select: parameterization.value_select,
            alert_required: parameterization.alert_required.toString() === "true",
        };        
        if(data.type_fields_id === FIELD_FILE) data.type_files_id = parseInt(parameterization.type_files_id) ?? 0;

        if (parseInt(parameterization.type_fields_id) === FIELD_SELECT && parameterization.value_select.split("*").length < 3) {
            MessageError("El campo de selección no tiene más de una opción");
            return;
        }
        if (parseInt(parameterization.type_fields_id) !== FIELD_SELECT) {
            delete data["value_select"];
        }

        if (id === ID_NOT_DEFINED) {
            const res = await service.post(data);
            if (res.is_ok) {
                MessageSuccess(res.message);
                dispatch(addParameterization({ value: res.parameterizarion_category_people }));
                history.goBack();
                return;
            }
            MessageError(res.message, service.status);
        } else {
            const res = await service.update(data);
            if (res.is_ok) {
                MessageSuccess(res.message);
                history.goBack();
                return;
            }
            MessageError(res.message, service.status);
        }
    }

    return (
        <div className='mx-4'>
            <BreadcrumbCustom
                title={id === ID_NOT_DEFINED ? "Crear Campo" : "Editar Campo"}
                routes={[
                    { name: "Tipos de caso", link: "/case-parameterization/list" },
                    { name: selectedCase.name ? selectedCase.name : "Seleccione tipo de caso", link: "/case-parameterization/list" },
                    { name: selectedCategory.name ? selectedCategory.name : "Seleccione catergoría", link: "/case-parameterization/category" },
                    { name: selectedCluster.name ? selectedCluster.name : "Seleccione grupo", link: `/case-parameterization/category/fields?legalCase=${selectedCase.id}&category=${selectedCategory.id}&cluster=${selectedCluster.id}` },
                ]}
                activeRoute={id === ID_NOT_DEFINED ? "Crear Campo" : "Editar Campo"}
            />

            <div className="col-md-12 table-data mt-3">
                <form onSubmit={handleSubmit}>
                    <div className="card-body">
                        <div className="row mb-2">
                            <div className='col-md-6'>
                                <label>Nombre del campo<b><i className="text-red">*</i></b></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    placeholder="Ingrese un nombre"
                                    value={parameterization.name}
                                    minLength={2}
                                    maxLength={50}
                                    autoComplete="off"
                                    onChange={setData}
                                    required
                                />
                            </div>

                            <div className="col-md-6">
                                <label>Tipo de campo<b><i className="text-red">*</i></b></label>
                                <select
                                    className="form-select"
                                    name="type_fields_id"
                                    value={parameterization.type_fields_id}
                                    onChange={setData}
                                    required
                                >
                                    <option value="">
                                        Seleccione
                                    </option>
                                    <option value="8">Texto</option>
                                    <option value="1">Número</option>
                                    <option value="2">Decimal</option>
                                    <option value="7">Correo Electrónico</option>
                                    <option value="9">Campo de Selección</option>
                                    <option value="3">Fecha</option>
                                    <option value="4">Hora</option>
                                    <option value="5">Fecha-Hora</option>
                                    <option value="6">Archivo</option>
                                </select>
                            </div>

                            <div className='col-md-3 mt-2'>
                                <label>Longitud mínima{!required && <b><i className="text-red">*</i></b>}</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="length_min"
                                    placeholder="Ingrese longitud mínima"
                                    value={parseInt(parameterization.length_min) === 0 ? "" : parameterization.length_min}
                                    min={0}
                                    onChange={setData}
                                    disabled={required}
                                    required={!required}
                                />
                            </div>
                            <div className='col-md-3 mt-2'>
                                <label>Longitud máxima{!required && <b><i className="text-red">*</i></b>}</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="length_max"
                                    placeholder="Ingrese longitud mínima"
                                    value={parseInt(parameterization.length_max) === 0 ? "" : parameterization.length_max}
                                    min={parameterization.length_min ? parseInt(parameterization.length_min) + 1 : 0}
                                    onChange={setData}
                                    disabled={required}
                                    required={!required}
                                />
                            </div>

                            <div className='col-md-6 mt-2'>
                                <div className="row">
                                    <div className="col">
                                        <label>Requerido<b><i className="text-red">*</i></b></label>
                                        <select
                                            className="form-select"
                                            name="required"
                                            value={parameterization.required}
                                            onChange={setData}
                                            required
                                        >
                                            <option value="">
                                                Seleccione
                                            </option>
                                            <option value={true}>Si</option>
                                            <option value={false}>No</option>
                                        </select>
                                    </div>
                                </div>
                                {
                                    parameterization.required.toString() === "false" &&
                                    <div className="row mt-1">
                                        <div className="form-check form-switch ms-3">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                role="switch"
                                                id="flexSwitchCheckChecked"
                                                checked={parameterization.alert_required}
                                                onChange={(e) => {
                                                    dispatch(reducerForm({
                                                        key: "alert_required",
                                                        value: !parameterization.alert_required,
                                                    }));
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">¿Alertar en caso de campo vacío?</label>
                                        </div>
                                    </div>
                                }
                            </div>

                            {
                                parseInt(parameterization.type_fields_id) === 6 &&
                                (
                                    <div className="col-md-6 mt-2">
                                        <label>¿Que tipo de archivo es?<b><i className="text-red">*</i></b></label>
                                        <select
                                            className="form-select"
                                            name="type_files_id"
                                            value={parameterization.type_files_id}
                                            onChange={setData}
                                        >
                                            <option value="">
                                                Seleccione
                                            </option>
                                            <option value="1">Documento</option>
                                            <option value="2">Comprimido</option>
                                            <option value="3">Imagen</option>
                                            <option value="4">Video</option>
                                            <option value="5">Audio</option>
                                        </select>
                                    </div>
                                )
                            }

                            <div className="col-6 mt-2">
                                {
                                    parseInt(parameterization.type_fields_id) === 9 &&
                                    <SelectControl fields={parameterization.value_select} onChange={(value) => {
                                        dispatch(reducerForm({
                                            key: "value_select",
                                            value,
                                        }));
                                    }} />
                                }
                            </div>

                        </div>

                        <div className='row'>
                            <div className='col mt-2'>
                                <button className='btn btn-success d-block ms-auto' type='submit'>
                                    <i className="fa-solid fa-paper-plane me-2" style={{ fontSize: '16px' }}></i>
                                    {id === 0 ? "Crear" : "Editar"} campo
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default FormParameterizationCategoryLegalCase;
