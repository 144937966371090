import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import IconButton from "../../../app/components/IconButton";

import serviceBusiness from '../services/serviceBusiness';
import { MessageSuccess } from "../../../utils/message";
import { setBusiness, deleteBusiness, plans, cleanData } from '../../../reducers/business/reducerBusiness';
import confirmDelete from "../../../utils/confirmDelete";

const ListBusiness = () => {
    const { listFilter } = useSelector(state => state.businessSlice);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        getBusiness();
    }, []);

    const getBusiness = async () => {
        const response = await serviceBusiness.getList();
        const { business = [] } = response;
        dispatch(setBusiness({ value: business }));
    }

    const deleObject = async (id) => {
        await serviceBusiness.delete(id);
        MessageSuccess("El registro ha sido eliminado exitosamente!");
        dispatch(deleteBusiness({ value: id }));
    }

    const handleEdit = (id) => {
        dispatch(cleanData());
        history.push(`/business/edit/${id}`)
    }

    return (
        <>
            <div className="order">
                {listFilter?.length === 0 ?
                    <div className="alert alert-warning text-center mt-2" role="alert">
                        No hay empresas que mostrar {":)"}
                    </div>
                    :
                    <div className="d-block rounded-3 clip-hide">
                        <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter?.length > 0 && "table-striped"}`}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>#</th>
                                    <th>NIT/CC</th>
                                    <th>Nombre</th>
                                    <th>Plan</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listFilter?.map((item, i) => (
                                        <tr key={item.id}>
                                            <td></td>
                                            <td>{i + 1}.</td>
                                            <td>{item.num_document}</td>
                                            <td>{item.name}</td>
                                            <td>{plans[item.plans_id]}</td>
                                            <td>
                                                <IconButton
                                                    icon="fa-solid fa-pen-to-square text-green"
                                                    title="Editar usuario"
                                                    onClick={() => handleEdit(item.id)}
                                                />
                                                <IconButton
                                                    icon="fa-solid fa-trash-can text-red"
                                                    title="Eliminar tipo de persona"
                                                    onClick={async () => await confirmDelete(() => deleObject(item.id))}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </>
    )

};

export default ListBusiness;