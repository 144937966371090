import { useDispatch } from "react-redux";
import { setDataCase } from "../../../../reducers/createCase/reducerCreateCase";
import { isURL } from "../../../Usuarios/utils/functions";

const FieldType = ({ param, cluster, id, dataCase, encodeDocument, aceptFile }) => {
    const dispatch = useDispatch();

    const renderFileInput = () => {
        const IsForEditingAndIsUrl = id !== 0 && isURL(dataCase[param.name]?.value);

        return (
            IsForEditingAndIsUrl
                ?
                <div className="row">
                    <div className='col-8'>
                        <input
                            className="form-control parametrizacion__form-input"
                            autoComplete="off"
                            type={'file'}
                            name={param.name}
                            required={param.required && id === 0}
                            aria-label={cluster.name}
                            onChange={(e) => encodeDocument(e, param)}
                            accept={aceptFile(param.type_files_id)}
                        />
                    </div>
                    <div className='col-4 p-0'>
                        <b><a href={dataCase[param.name]?.value} className="d-block mt-1" target="_blank">Ver actual</a></b>
                    </div>
                </div>
                :
                <input
                    className="form-control parametrizacion__form-input"
                    autoComplete="off"
                    type={'file'}
                    name={param.name}
                    required={param.required && id === 0}
                    aria-label={cluster.name}
                    onChange={(e) => encodeDocument(e, param)}
                    accept={aceptFile(param.type_files_id)}
                />
        );
    };

    const renderSelectInput = () => (
        <select
            className="form-select parametrizacion__form-input"
            autoComplete="off"
            name={param.name}
            value={dataCase[param.name]?.value}
            aria-label={cluster.name}
            onChange={(e) => {
                const { id, ...other } = param;
                dispatch(setDataCase({
                    key: param.name,
                    value: {
                        parameterization_id: param.id,
                        value: e.target.value,
                        ...other,
                    }
                }))
            }}
            required={param.required}
        >
            <option value="">
                Seleccione
            </option>
            {param.value_select.split("*")?.map((item, i) => {
                if (item !== "") {
                    return (
                        <option value={item} key={item}>{item}</option>
                    );
                }
                return null;
            })}
        </select>
    );

    const renderTextInput = () => {
        const inputTypeMap = {
            1: 'number',
            2: 'number',
            3: 'date',
            4: 'time',
            5: 'datetime-local',
            7: 'email',
            8: 'text'
        };

        const inputType = inputTypeMap[param.type_fields_id];

        return (
            <input
                className="form-control parametrizacion__form-input"
                autoComplete="off"
                value={dataCase[param.name]?.value}
                type={inputType}
                name={param.name}
                required={param.required}
                maxLength={(inputType === 'number' || inputType === 'text') && param.length_max}
                minLength={(inputType === 'number' || inputType === 'text') && param.length_min}
                placeholder="Ingresa un valor"
                aria-label={cluster.name}
                onChange={(e) => {
                    const { id, ...other } = param;
                    dispatch(setDataCase({
                        key: param.name,
                        value: {
                            parameterization_id: param.id,
                            value: e.target.value,
                            ...other,
                        }
                    }))
                }}
            />
        );
    };

    return (
        param.type_fields_id === 6
            ? renderFileInput()
            : param.type_fields_id === 9 ? renderSelectInput()
                : renderTextInput()
    );
}

export default FieldType;
