import { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchTypepeople from "../../../TercerosParam/componentes/searchTypepeople";
import ModalLabelActor from "./ModalLabelActor";

import { addActor, deleteActor, setRefreshActors } from "../../../../reducers/documents/reducerGenerateDocument";
import { MessageError } from "../../../../utils/message";
import confirmDelete from "../../../../utils/confirmDelete";
import { toast } from "../../../../utils/toast";
import GeneralService from "../../../../services/GeneralService";

const TabActors = () => {
    const { listFilter } = useSelector(state => state.typepeopleSlice);
    const { dataDocument, listActorsSelected } = useSelector(state => state.documentsAutoSlice);
    const [show, setShow] = useState(false);
    const [actor, setActor] = useState(0);
    const dispatch = useDispatch();

    const insertActor = async (type_peoples) => {
        const service = new GeneralService("actors-documents");
        const data = {
            documents_auto_id: dataDocument.id,
            type_peoples_id: type_peoples.id,
        };
        const res = await service.post(data);

        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(addActor({ value: { ...res.actors_documents, type_peoples } }));
            return;
        }
        MessageError(res.message, service.status);
    }

    const removeActor = async (id) => {
        const service = new GeneralService("actors-documents");
        const res = await service.delete(id);
        if (res.is_ok) {
            toast(dispatch, res.message);
            dispatch(deleteActor({ value: id }));
            return;
        }
        MessageError(res.message, service.status);
    }

    const removeLabel = async (actorId, labelId) => {
        const service = new GeneralService("actors-documents/label/remove");
        const res = await service.post({ actor_id: actorId, label_id: labelId });
        if (res.is_ok) {
            toast(dispatch, "Proceso exitoso");
            dispatch(setRefreshActors());
            return;
        }
        MessageError(res.message, service.status);
    }

    return (
        <>
            <div className="process-tab">
                <div className="row">
                    <div className="col-7">
                        <h5><b>Selecciona los actores</b></h5>
                        <p>Puedes seleccionar uno o varios tipos de personas</p>

                        <SearchTypepeople />
                        {
                            listFilter.length === 0 ?
                                <div className="alert alert-warning text-center mt-4" role="alert">
                                    No hay tipos de persona que mostrar
                                </div>
                                :
                                <div className="d-block rounded-3 clip-hide mt-4">
                                    <table className={`table table-responsive table-header-custom animate__animated animate__fadeIn ${listFilter.length > 0 && "table-striped"}`}>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>#</th>
                                                <th>Nombre </th>
                                                <th>Descripción</th>
                                                <th>Seleccionar</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                listFilter.map((item, i) => (
                                                    <tr key={item.id}>
                                                        <td></td>
                                                        <td>{i + 1}.</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.description}</td>
                                                        <td>
                                                            <button title="Agregar tipo de persona" className="btn btn-success d-block mx-auto"
                                                                type="button"
                                                                onClick={async () => await insertActor(item)}
                                                            >
                                                                <i className="fa-solid fa-plug"></i>
                                                            </button>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                        }

                    </div>

                    <div className="col">
                        <div className="actors-selected__tab">
                            <h5 className="text-purple font-lilita-one text-center pb-2">
                                <i className="fa-regular fa-circle-check me-2"></i>
                                Seleccionados
                            </h5>
                            <hr style={{ color: "green" }} />
                            {
                                listActorsSelected.map(actor => <Fragment key={actor.id}>
                                    <div className="row">
                                        <div className="col-7">{actor.type_peoples.name}</div>

                                        <div className="col-3">

                                        </div>

                                        <div className="col-1">
                                            <i title="Eliminar Actor" className="fa-solid fa-trash text-redligth"
                                                role="button"
                                                onClick={async () => await confirmDelete(() => removeActor(actor?.id))}
                                            ></i>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            {
                                                actor?.labels?.map(label => <span
                                                    key={label.id}
                                                    className="badge rounded-pill bg-purple mx-1 my-1"
                                                    role="button"
                                                >
                                                    <span className="text-white">{label.name}</span>
                                                    <i className="fa-solid fa-circle-xmark ms-1"
                                                        title="Eliminar"
                                                        onClick={async () => await confirmDelete(() => removeLabel(actor?.id, label.id))}
                                                    >
                                                    </i>
                                                </span>)
                                            }
                                            <span
                                                title="Agregar etiqueta" className="badge rounded-3 bg-light-purple p-2 mx-1 my-1"
                                                role="button" onClick={() => {setActor(actor.id) ;setShow(true);}}
                                            >
                                                <i className="fa-solid fa-plus me-1 text-purple"></i>
                                                <span className="text-purple">ETIQUETA</span>
                                            </span>
                                        </div>
                                    </div>
                                    <hr className="mt-1" />
                                </Fragment>)
                            }
                        </div>
                    </div>

                </div>
            </div>
            <ModalLabelActor show={show} setShow={setShow} actorId={actor} />
        </>
    );
}

export default TabActors;