import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import data from '@emoji-mart/data'
import EmojiPicker from "@emoji-mart/react";

import { LoadingMessage } from '../../Chat/components/LoadingMessage';
import MessageItem from '../../Chat/components/MessageItem';

import { reducerMessage } from '../../../reducers/chatgpt/reducerChatGpt';
import { startSendMessage } from '../../../reducers/chatgpt/thunks';
import { ACTION_ADD_TO_END, ACTION_REPLACE_CONTENT } from '../constant/constant';

const ModalChatParameterization = ({ show, setShow, onChangeContent }) => {
    const { url_avatar, name } = useSelector(state => state.loginSlice.profile);
    const { message, chat, isLoading } = useSelector(state => state.chatGptSlice);
    const [showEmojis, setShowEmojis] = useState(false);
    const dispatch = useDispatch();
    const chatRef = useRef(null);
    const textareaRef = useRef(null);
    const buttonEmojiPickerRef = useRef(null);
    const emojiPickerRef = useRef(null);

    const setData = (e) => {
        dispatch(reducerMessage(e.target.value));
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (event.shiftKey) return;

            event.preventDefault();
            handleSubmit();
        }
    };

    const onEmojiSelect = ({ native }) => {
        const textarea = textareaRef.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const newText = message.slice(0, start) + native + message.slice(end);

        setTimeout(() => {
            textarea.selectionStart = textarea.selectionEnd = start + native.length;
            textarea.focus();
        }, 0);
        dispatch(reducerMessage(newText));
    }

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        dispatch(startSendMessage(chat._id, message));
    }

    const handleAddContentToEnd = (messageContent) => {
        onChangeContent(messageContent, ACTION_ADD_TO_END);
        setShow({ modal: 2, show: false });
    }

    const replaceContent = (messageContent) => {
        onChangeContent(messageContent, ACTION_REPLACE_CONTENT);
        setShow({ modal: 2, show: false });
    }

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [chatRef.current, show, chat]);

    useEffect(() => {
        if (textareaRef.current) {
            const textarea = textareaRef.current;
            if(message.length > 130) textarea.style.height = "110px";
            if (textarea.scrollHeight > 160) return;
            textarea.style.height = "25px";
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }, [message]);

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    const handleDocumentClick = (e) => {
        if (buttonEmojiPickerRef.current && emojiPickerRef.current) {
            if (!buttonEmojiPickerRef.current.contains(e.target) && !emojiPickerRef.current.contains(e.target)) {
                setShowEmojis(false);
            }
        }
    };

    return (
        <Modal show={show} onHide={() => setShow({ modal: 2, show: false })} size='xl'>
            <Modal.Header className='d-flex bg-green pe-4 justify-content-center align-items-center' closeButton>
                <i className="fa-solid fa-robot fs-5 me-2"></i>
                <h5 className="font-lato mb-0">Asistente</h5>
            </Modal.Header>
            <Modal.Body style={{ minHeight: "85vh", maxHeight: "85vh" }}>
                <div className="chat-section">
                    <div ref={chatRef} className="messages">
                        <MessageItem
                            avatar={<i className="fa-solid fa-robot"></i>}
                            showAvatar
                            isExternal={true}
                            isLarge={true}
                            message={{ content: "Hola estoy aquí para guiarte en el proceso de parametrización de tus formatos de documento. ¿En qué puedo ayudarte?" }}
                        />

                        {
                            chat.messages?.map((itemMessage, i) => {
                                if (i < 1) return null
                                return itemMessage.role === "system" || itemMessage.role === "assistant"
                                    ?
                                    <MessageItem
                                        avatar={<i className="fa-solid fa-robot"></i>}
                                        showAvatar
                                        isExternal={true}
                                        message={itemMessage}
                                        isLarge={true}
                                        options={
                                            <div className="row mt-2">
                                                <div className="col">
                                                    <div
                                                        onClick={() => handleAddContentToEnd(itemMessage.content)}
                                                        className="option__editor bg-purple text-white" role='button'
                                                    >
                                                        <i className="fa-solid fa-file-circle-plus me-1 text-white"></i>
                                                        Añadir al editor
                                                    </div>
                                                    <div
                                                        onClick={() => replaceContent(itemMessage.content)}
                                                        className="option__editor bg-orange" role='button'
                                                    >
                                                        <i className="fa-solid fa-file-contract me-1"></i>
                                                        Probar en el editor
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    />
                                    :
                                    <MessageItem
                                        avatar={<img src={url_avatar} alt={name} />}
                                        showAvatar
                                        message={itemMessage}
                                        isLarge={true}
                                    />
                            })
                        }
                        {
                            isLoading
                            &&
                            <LoadingMessage avatar={<i className="fa-solid fa-robot"></i>} />
                        }
                    </div>
                </div>
                <form className="input-container" onSubmit={handleSubmit}>
                    <div>
                        <button type="button" title="Eliminar contenido del editor" className="btn btn-success rounded-pill px-2 py-1 mx-1 float-end"
                            onClick={() => { }}
                        >
                            <i className="fa-solid fa-eraser text-white"></i>
                        </button>
                    </div>

                    <div className="text-area__chat">
                        <div ref={buttonEmojiPickerRef} className="emoji-picker__chat">
                            <i className="fa-solid fa-face-smile" onClick={() => setShowEmojis(!showEmojis)}></i>
                            {
                                showEmojis
                                &&
                                <div ref={emojiPickerRef} style={{
                                    position: 'absolute',
                                    top: -450,
                                    left: 0,
                                    zIndex: 1000,
                                    boxShadow: '0 5px 10px rgba(0,0,0,0.2)',
                                }}>
                                    <EmojiPicker theme="light" data={data} onEmojiSelect={onEmojiSelect} />
                                </div>
                            }
                        </div>
                        <textarea
                            ref={textareaRef}
                            className='chat-textarea'
                            placeholder="Escribe un mensaje"
                            value={message}
                            onChange={setData}
                            onKeyDown={handleKeyDown}
                        ></textarea>
                    </div>

                    <div>
                        <button type="submit" title="Enviar" className="btn btn-success rounded-pill px-2 py-1 send">
                            <i className="fa-solid fa-paper-plane text-white"></i>
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalChatParameterization;