
const StepNavigationCategoryGroups = ({ clusters, currentStep, updateStep }) => {

    return (
        <div className="stepWrapper">
            {
                clusters?.map((item, index) => {
                    return (
                        <div key={index} className={"stepBlock" + (currentStep === index + 1 ? " selected" : "")}>
                            <div className="circleWrapper" onClick={() => updateStep(index + 1)}>
                                <div className="circle"><strong>{index + 1}</strong></div>
                            </div>
                            <span> Sección {index + 1} </span>
                        </div>
                    )
                })
            }
        </div>
    )
}
export default StepNavigationCategoryGroups;